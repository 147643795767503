@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .main_content {
    position: relative;
    flex: 1;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;

    .form {
      width: 50%;

      .settings {
        margin-bottom: 1rem;

        &:nth-child(2) {
          margin-bottom: 1rem;
          border-bottom: 1px solid $color-gray-5;
        }
      }

      .row {
        display: flex;
        width: 100%;
        font-size: 0.9rem;
        justify-content: space-between;
        margin-bottom: 1.2rem;
      }

      .customer_title {
        font-size: 1rem;
        padding-bottom: 0.5rem;
        margin: 0 0 1rem;
        border-bottom: 1px solid $color-gray-5;
      }
    }

    .receipt_example {
      width: 50%;
      margin: 0 6rem;

      .example_content {
        padding: 1rem;
        width: 500px;
        border: 1px solid $color-gray-5;
        border-radius: 4px;
        font-size: 0.9rem;
      }

      .header {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $color-gray-5;
      }

      .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0.5rem 0;
      }

      .information {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          flex: 50%;
          margin-bottom: 0.3rem;

          &:nth-child(even) {
            text-align: end;
          }
        }
      }

      .dish_section {
        margin-bottom: 1rem;
      }

      .dish_name {
        font-size: 0.8rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
        margin: 0 0 0.5rem;
        border-bottom: 1px solid $color-gray-5;
      }

      .ingredient {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .additional_info_list {
        font-size: 1rem;
        padding-left: 1.5rem;
        margin: 0;
        list-style-type: none;

        li {
          margin-bottom: 0.3rem;
        }
      }

      .order_note {
        font-size: 1rem;
        padding-bottom: 1rem;
      }

      .note_title {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .note_text {
        margin: 0;
      }

      .customer_section {
        padding-top: 1rem;
      }

      .section_title {
        font-size: 0.8rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
        margin: 0 0 0.5rem;
        border-bottom: 1px solid $color-gray-5;
      }

      .customer_info {
        font-style: normal;

        p {
          margin: 0 0 0.3rem 0;
        }
      }

      .customer_name,
      .customer_phone {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 900px) {
  .main_container {
    .main_content {
      flex-direction: column;

      .form {
        width: 100%;
        margin-bottom: 2rem;
      }

      .receipt_example {
        width: 100%;
        margin: 0;

        .example_content {
          width: 470px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .main_container {
    .main_content {
      .receipt_example {
        .example_content {
          width: 380px;
        }
      }
    }
  }
}
