@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .main_content {
    position: relative;
    flex: 1;
    padding: 15px;
  }
}

.tableTopBorder {
  border-bottom: 1px solid $border_color;
  margin-bottom: 15px;
}

.borderBox {
  border: #d6e2ee 1px solid;
}

.boxBody {
  background: $color_white_3;
  padding: 20px;
  border-radius: 0;
  margin-bottom: 1.5rem;
}

//---transactions list styles---//
.created_at {
  font-weight: bold;
  word-break: normal;
}

.details {
  a {
    color: $accent_color;

    &:hover {
      text-decoration: none;
      color: $accent_color;
    }
  }
}
