@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .add_order {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 1rem;

      .input_reason {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .add_button {
        margin-top: 24px;
      }
    }

    .action_buttons {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;

      .save_button {
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .input_container {
      &.dynamic_input {
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .main_container {
    .content {
      .add_order {
        margin-bottom: 2rem;

        .input_reason,
        .add_button {
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }
}
