@import '../../../assets/styles/colors';

$input-height: 40px;

.container {
  width: 100%;
  position: relative;

  .label {
    display: inline-block;
    color: $label_color;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.4s ease;
  }

  &:has(input:disabled) {
    .label {
      color: $text_disabled_color;
    }
  }

  .asterisk::after {
    color: $red_error_color;
    content: '*';
  }

  input {
    display: block;
    width: 100%;
    height: $input-height;
    color: $text_color;
    background-color: $background_color;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border: $border_color 1px solid;
    border-radius: 6px;
    box-shadow: none;
    background-clip: padding-box;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus {
      border-width: 2px;
      border-color: $border_focus_color;
      box-shadow: none;
      outline: none;
    }

    &:not(select) {
      appearance: none;
    }

    &:disabled {
      border-color: $border_disabled_color;
    }
  }
}
