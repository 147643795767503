@import '../../../assets/styles/colors';

$toogler-width: var(--toggler-width); // toggler width
$toogler-height: 40px; // toggler height
$border-size: 1px; // border size
$padding: 2px; // space between container and slider

$slider-width: calc(($toogler-width / 2) - (($border-size + $padding) * 2)); // slider with
$slider-height: calc($toogler-height - (($border-size + $padding) * 2)); // slider height
$slider-translate: calc($toogler-width / 2); // translate value

$background_color: $color_white_3;
$background_slider_color: $color_neutral_100;
$border_color: $color_neutral_300;
$text_color: $color_neutral_900;

.container {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $background_color;
  width: $toogler-width;
  height: $toogler-height;
  border: $border-size solid $border_color;
  border-radius: 6px;

  .slider {
    position: absolute;
    top: $padding;
    left: $padding;
    transform: translateX($slider-translate);
    transition: transform 0.2s ease;
    border-radius: 4px;
    background-color: $background_slider_color;
    width: $slider-width;
    height: $slider-height;

    &.active {
      transform: translateX(0px);
    }
  }

  span {
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $text_color;
    user-select: none;
    cursor: pointer;
    z-index: 99;
  }
}
