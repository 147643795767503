@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/fonts';

@mixin transition {
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  background-color: $background_color;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .backdrop {
    position: absolute;
    inset: 0;
    background-color: $background_backdrop_color;
    opacity: 0.4;
    z-index: 99;
  }

  .no_data {
    @include flex-center;
    flex-direction: column;
    padding: 3rem 0;
    border: {
      bottom: 1px solid $color_gray_5;
      top: 1px solid $color_gray_5;
    }

    .icon {
      font-size: 2rem;
      color: $nodata_color;
      margin-bottom: 0.5rem;
    }

    span {
      color: $nodata_color;
      font: {
        weight: 500;
        size: 18px;
      }
    }
  }

  .no_data_simple {
    @include flex-center;
    flex-direction: column;
    padding: 1rem 0;
    border: 1px solid $color_gray_5;
    border-top: none;

    .message {
      color: #808080;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.table {
  display: table;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  color: $text_color;
  line-height: 20px;
  border-collapse: collapse;
  text-align: center;
}

.table_simple {
  margin-bottom: 0;
}

.row {
  position: relative;
  display: table-row;
  background-color: $background_row_color;
  border: 1px solid $border_table_color;
  text-decoration: none;
  outline: none;
  @include transition;

  &:nth-child(even) {
    background-color: $background_row_even_color;
  }

  &.header {
    background-color: $background_row_color;

    &:has(.head_drag) {
      .cell {
        &:nth-child(2) {
          text-align: left;
        }
      }
    }

    .head_drag {
      width: 5%;
    }

    .cell {
      padding: 0.6rem 0.75rem;
      color: $text_color;
      font-weight: 700;
      white-space: nowrap;

      &:first-child {
        text-align: left;
      }
    }
  }

  &.body {
    &:has(.drag_cell) {
      .cell {
        &:nth-child(1) {
          text-align: center;
        }
      }
    }

    .cell {
      vertical-align: middle;
      color: $text_color;
      font-weight: 400;
      word-break: break-word;
      max-width: var(--max-cell-width);

      &:first-child {
        text-align: left;
      }
    }

    .drag_cell {
      vertical-align: middle;
      text-align: center;

      .drag_icon {
        display: $text_color;
        cursor: move;
      }
    }
  }
}

.cell {
  display: table-cell;
  padding: 10px;
  text-align: center;
}

.control_icons {
  @include flex-center;

  .row_actions_button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:first-child {
      margin-right: 6px;
    }

    svg {
      color: $accent_color;
      width: 22px;
      height: 22px;
    }
  }
}

@media (max-width: 768px) {
  .table {
    min-width: 282px;
  }

  .header.row {
    display: none;
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    &.body {
      .cell {
        max-width: none;
      }
    }

    &:has(.drag_cell) {
      .cell {
        &:nth-child(2) {
          border: 1px solid $accent_color;
          color: $invert_text_color;
          background-color: $accent_color;

          &:has(input) {
            color: inherit;
            background-color: $background_row_color;
          }

          &::before {
            color: $invert_text_color;
            background-color: $accent_color;
            border: none;
          }
        }
      }
    }
  }

  .head_drag {
    display: none;
  }

  .row.body .drag_cell {
    display: none;
  }

  .cell {
    position: relative;
    padding-left: 50%;
    text-align: left;
    min-height: 2em;
    overflow-wrap: break-word;

    &::before {
      content: attr(data-label);
      position: absolute;
      width: 45%;
      inset-block: 0;
      inset-inline-start: 0;
      font-weight: 600;
      color: $color_black_3;
      background-color: $background_row_even_color;
      border-right: 1px solid $border_table_mobile_color;
      @include flex-center;
      white-space: nowrap;
      padding-left: 6px;
    }

    &:nth-child(odd) {
      background-color: $border_table_mobile_color;

      &::before {
        background-color: $border_table_mobile_color;
      }
    }

    &:first-child {
      color: $invert_text_color;
      background-color: $accent_color;

      &::before {
        color: $invert_text_color;
        background-color: $accent_color;
        border: none;
      }
    }
  }
}
