@import '../../../../../assets/styles/colors';

.menu_item {
  width: 180px;
  background: $color_white_3;
  border: 1px solid $color_neutral_300;
  border-radius: 6px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  margin: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;

  .info_wrapper {
    padding: 6px 6px 0;

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 4px;

      .title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-decoration: none;
        text-transform: capitalize;
        color: $color_neutral_700;
        margin: 0 10px 0 0;
        cursor: pointer;
      }

      .actions {
        position: relative;
        color: $color_blue_1;
        cursor: pointer;

        .ellipsis_icon {
          width: 16px;
          height: 16px;
        }

        .dropdown {
          position: absolute;
          top: -6px;
          left: 20px;
          min-width: 100px;
          background-color: $color_white_3;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          list-style: none;
          padding: 8px 0;
          margin: 0;
          z-index: 100;

          &.out_of_edge {
            top: 20px;
            left: auto;
            right: 0;
          }

          li {
            padding: 4px 16px;
            cursor: pointer;
            transition: background-color 0.2s;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $color_neutral_700;

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }

      .price {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color_accent_400;
      }

      .categories_wrapper {
        max-width: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        white-space: nowrap;

        .category {
          padding: 2px 6px;
          border-radius: 14px;
          color: $color_white_3;
          background: $color_neutral_700;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          word-wrap: normal;
          margin-bottom: 2px;
          cursor: pointer;
        }
      }

      .pos_text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color_accent_400;
      }

      .pos_switch {
        width: auto;
        cursor: pointer;
        position: relative;

        & label {
          position: relative;
          top: 4px;
        }
      }
    }
  }

  .image_wrapper {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
