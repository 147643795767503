@import '../../../assets/styles/colors';

.checkbox_wrapper {
  display: flex;
  align-items: center;

  .checkbox_input {
    display: none;
    user-select: none;
  }

  .checkbox_label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;

    & svg {
      width: 10px;
    }

    .custom_checkbox {
      width: 22px;
      height: 22px;
      border: 1px solid $border_color;
      background-color: $background_color;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:
        background-color 0.2s ease,
        border-color 0.2s ease;
      padding: 4px;

      .check_icon {
        color: $background_color;
        opacity: 0;
      }
    }
  }

  .checkbox_input:checked + .checkbox_label .custom_checkbox {
    background-color: $accent_color;
    border-color: $accent_color;
  }

  .checkbox_input:checked + .checkbox_label .custom_checkbox .check_icon {
    opacity: 1;
  }
}
