@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .main_content {
    position: relative;
    flex: 1;
    padding: 15px;

    .form {
      .settings {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 515px;
        width: 45%;
        column-gap: 2rem;
        margin-bottom: 1rem;
      }

      .row {
        display: flex;
        width: 100%;
        font-size: 0.9rem;
        justify-content: space-between;
        margin-bottom: 1.2rem;
      }

      .customer_title {
        font-size: 1rem;
        padding-bottom: 0.5rem;
        margin: 0 0 1rem;
        border-bottom: 1px solid $color-gray-5;
      }

      .save_button {
        width: 170px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main_container {
    .main_content {
      .form {
        .settings {
          height: 100%;
          width: 100%;
        }

        .save_button {
          width: 100%;
        }
      }
    }
  }
}
