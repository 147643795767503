@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.edit_timesheet_details {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .back_button {
      margin-bottom: 20px;
      width: 100%;

      & button {
        width: 100%;
        color: $color_blue_1;
        font-style: 'SFProTextBold', sans-serif;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        border: none;
        border-bottom: 1px solid $border_color;
        background: none;
      }
    }

    .title_and_date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .date {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
      }
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      margin-top: 20px;
    }
  }
}
