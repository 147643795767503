@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.edit_discount_modal {
  max-width: 450px;

  .modal_content {
    min-height: 100%;
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .modal_header {
      position: relative;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 2rem;
      }
    }

    .modal_body {
      .edit_discount_wrapper {
        margin-bottom: 1rem;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0.75rem;
    }
  }
}
