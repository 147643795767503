@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.dashboard {
  width: 100%;
  padding: 20px;

  .title {
    margin-bottom: 1rem;
  }

  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    margin-bottom: 40px;

    .search_button {
      margin-right: 10px;
    }
  }

  .metrics {
    width: 100%;

    .row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .graph_item,
      .statistics,
      .graph_item_full {
        flex: 1 1 calc(33% - 13.33px);
        border: 1px solid $color_gray_2;
        padding: 14px;
        margin-bottom: 20px;
        flex: 1;

        .subtitle {
          font-size: 16px;
          margin-bottom: 1rem;
        }

        .graph_content {
          min-height: 500px;
          text-align: center;
          color: $color_gray_2;
        }
      }

      .statistics {
        margin-right: 0;

        .metrics {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
      }

      .graph_item,
      .statistics {
        min-width: 400px;
      }

      .graph_item_full {
        flex: 1 1 100%;
        width: 100%;
        margin-right: 0;

        .graph_content {
          min-height: 300px;
        }
      }

      .graph_item_align_left {
        // TODO: There will be adding this graph after the MVP (Do not delete)
        min-height: 600px;
        //max-width: 600px;
        //flex-grow: 1;
      }
    }
    .row_align_left {
      justify-content: flex-start;
    }
  }

  // TODO: There will be adding this graph after the MVP (Do not delete)
  // .footer {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   width: 100%;
  //   padding: 14px;
  //   border: 1px solid $color_gray_2;

  //   .links {
  //     .link_wrapper {
  //       .page_name {
  //         font-weight: 600;
  //         color: $color-black_3;
  //         margin-right: 6px;
  //       }

  //       .link {
  //         font-weight: 600;
  //         color: $color-blue_1;
  //       }

  //       &:first-child {
  //         margin-bottom: 14px;
  //       }
  //     }
  //   }

  //   .qr_code_wrapper {
  //     .qr_code {
  //       & img {
  //         width: 200px;
  //         height: 200px;
  //       }
  //     }
  //   }
  // }
}

@media (max-width: 1600px) {
  .row {
    flex-wrap: wrap;
  }

  .graph_item_align_left {
    flex-grow: 1 !important;
  }
}

@media (max-width: 860px) {
  .search_button {
    margin-top: 20px;
  }
}

@media (max-width: 860px) {
  .search_button {
    margin-top: 20px;
  }
}

// TODO: There will be adding this graph after the MVP (Do not delete)
// @media (max-width: 768px) {
//   .footer {
//     flex-direction: column;

//     .links {
//       margin-bottom: 20px;
//     }

//     .qr_code_wrapper {
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }
