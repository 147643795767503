@import '../../../assets/styles/colors';

.main_content {
  overflow-y: auto;
  height: 100%;
  flex: 1 1 80%;
  width: auto;
  background-color: $main_neutral_background_color;
  transition:
    flex,
    opacity 0.3s;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color_accent_500;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $color_accent_600;
  }
  &::-webkit-scrollbar-thumb:active {
    background: $color_accent_700;
  }
}

@media (max-width: 1100px) {
  .main_content {
    flex: 1 1 72%;
  }
}

@media (max-width: 768px) {
  // .main_content {
  //   padding: 20px 10px 10px 10px;
  // }
}
