.total_range_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  .inputs {
    display: flex;
    align-items: center;

    .input {
      min-width: 100px;
    }
  }

  .text_separator {
    padding: 0 2rem;
  }
}
