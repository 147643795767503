@import '../../../assets/styles/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'SFProText-Regular', sans-serif;

  .label {
    display: flex;
    align-items: center;
    font-size: 15px;

    .selector {
      margin-right: 14px;
    }

    select {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .controls {
    margin: 0.5rem;

    .nav {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;

      button {
        font-size: 14px;
        font-weight: 600;
        color: $accent_color;
        background-color: transparent;
        padding: 0.5rem 0.75rem;
        border: none;
        cursor: pointer;
        user-select: none;
        box-sizing: border-box;
        text-decoration: none;
        text-align: center;
        text-decoration-thickness: auto;
        -webkit-user-select: none;
        touch-action: manipulation;

        &:disabled {
          color: $text_disabled_color;
          cursor: inherit;
        }
      }

      // .prev {

      // }
    }
  }
}
