@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.search {
  margin-bottom: 2rem;

  .search_field {
    display: flex;
    gap: 1rem;
  }
}

.filter_by {
  margin-bottom: 2rem;

  .clear_filter {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 0.5rem;
    cursor: pointer;

    .cross_icon {
      display: inline-flex;

      svg {
        color: $accent_color;
        font-size: 0.8rem;
      }
    }

    .text {
      color: $accent_color;
    }
  }
}

.filter_container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color_gray_5;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  .filter_title {
    font-size: 1.1rem;
    margin: 0;
  }
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.field {
  flex: 0 0 calc(50% - 0.5rem);
}
