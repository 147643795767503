@import '../../../assets/styles/colors';

@mixin icon-styles($position) {
  color: $color_white_3;
  position: absolute;
  #{$position}: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
}

.container {
  position: relative;
  min-width: 120px;
  min-height: 40px;
  cursor: pointer;

  button {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding: 8px 16px;
    background-color: $color_accent_800;
    color: $color_white_3;
    line-height: 24px;
    border: none;
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;

    &:empty {
      min-width: 40px;
      padding: 8px 8px;
    }

    &:hover {
      background-color: $color_accent_600;
    }

    &:active {
      background-color: $color_accent_900;
    }

    &:disabled {
      background-color: $color_accent_100;
      color: $color_accent_400;
    }
  }

  &:has(.prefix_icon) {
    button {
      padding-left: 36px;
    }
  }

  &:has(button:disabled) {
    .prefix_icon,
    .suffix_icon {
      color: $color_accent_400;
    }
  }

  &:has(.suffix_icon) {
    button {
      padding-right: 36px;
    }
  }

  &:has(.variant_secondary) {
    .prefix_icon {
      color: $color_accent_800;
    }

    .suffix_icon {
      color: $color_accent_800;
    }
  }

  &:has(.variant_transparent) {
    .prefix_icon {
      color: $color_accent_500;
    }

    .suffix_icon {
      color: $color_accent_500;
    }
  }

  &:has(button:empty) {
    .prefix_icon {
      left: 14px;
    }
  }

  &:has(button:empty) {
    .suffix_icon {
      right: 14px;
    }
  }

  .prefix_icon {
    @include icon-styles(left);
  }

  .suffix_icon {
    @include icon-styles(right);
  }

  .variant_secondary {
    background-color: $color_accent_200;
    color: $color_accent_800;

    &:hover {
      background-color: $color_accent_300;
    }

    &:active {
      background-color: $color_accent_400;
    }

    &:disabled {
      background-color: $color_accent_100;
      color: $color_accent_400;
    }
  }

  .variant_transparent {
    background-color: transparent;
    color: $color_accent_500;

    &:hover,
    &:active {
      background-color: transparent;
    }

    &:disabled {
      background-color: transparent;
      color: $color_accent_300;
    }
  }
}
