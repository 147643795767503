@import '../../../assets/styles/colors';

.subtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: $title_color;
  border-bottom: 1px solid $border_color;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}
