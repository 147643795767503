@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .dineInSubtitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .form {
      max-width: 1000px;
      margin-bottom: 30px;

      .subtitle,
      .dineInSubtitle {
        font-size: 16px;
        margin-bottom: 30px;
      }

      .form_fields {
        .checkbox_wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }

        .inputs {
          display: flex;

          .input_wrapper {
            max-width: 520px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 300px;
            margin-right: 20px;
            margin-bottom: 30px;
          }
        }
      }

      .buttons_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
      }
    }

    .no_charges {
      color: $color_accent;
      font-size: 28px;
      width: 100%;
      margin: 0 auto;
      text-align: left;
      padding: 100px 50px;
    }
  }
}

@media (max-width: 768px) {
  .inputs {
    flex-direction: column;
  }

  .form {
    max-width: 100%;
  }

  .input_wrapper,
  .buttons_wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .buttons_wrapper {
    justify-content: space-between !important;
  }
}

@media (max-width: 540px) {
  .dineInSubtitleWrapper {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 20px;
  }
}
