@import '../../../../../../assets/styles/colors';

.modal {
  max-width: 450px;

  .container {
    min-height: 100%;
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .header {
      margin-bottom: 2rem;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      .description {
        color: $text_placeholder_color;
        font-weight: 400;
      }
    }

    .form_container {
      position: relative;

      .fields {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .field {
          position: relative;
          width: 100%;
          margin-bottom: 1rem;

          & label {
            font-size: 14px;
            font-weight: 500;
            color: $text_color;
            margin-bottom: 0.5rem;
            display: block;
          }
        }

        .selector_field {
          position: relative;
        }
      }

      .action_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.75rem;
      }
    }
  }
}
