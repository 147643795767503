// Color Variables
$color_white_1: #f9f9f9;
$color_white_2: #ececec;

$color_white_3: #fff; // list component (background) //Change the name

$color_accent_50: #f8fafc;
$color_accent_100: #f1f5f9;
$color_accent_200: #e2e8f0;
$color_accent_300: #cbd5e1;
$color_accent_400: #94a3b8;
$color_accent_500: #64748b;
$color_accent_600: #475569;
$color_accent_700: #334155;
$color_accent_800: #1e293b;
$color_accent_900: #0f172a;

$color_neutral_50: #f8fafc;
$color_neutral_100: #f1f5f9;
$color_neutral_200: #e2e8f0;
$color_neutral_300: #cbd5e1;
$color_neutral_400: #94a3b8;
$color_neutral_500: #64748b;
$color_neutral_600: #475569;
$color_neutral_700: #334155;
$color_neutral_800: #1e293b;
$color_neutral_900: #0f172a; // fonts

$color_additional_green_500: #10b981;
$color_additional_green_700: #047857;
$color_additional_green_900: #064e3b;
$color_additional_red_500: #ef4444;
$color_additional_red_700: #ef4444;
$color_additional_red_900: #ef4444;
$color_additional_link: #2563eb;

//---------Main and fileds preset----------//
$main_border_color: $color_neutral_300;
$main_background_color: $color_white_3;
$main_neutral_background_color: $color_neutral_50;

$link_color: $color_additional_link;
$red_color: $color_additional_red_500;

$background_hover_color: $color_neutral_200;

$accent_color: $color_accent_800;
$light_accent_color: $color_accent_400; // spinner
$background_color: $color_white_3;
$border_color: $color_neutral_300;
$border_focus_color: $color_neutral_400;
$border_disabled_color: $color_neutral_400;
$icon_color: $color_neutral_400;
$title_color: $color_neutral_700; // title, tag color
$text_color: $color_neutral_900;
$invert_text_color: $color_white_3;
$text_placeholder_color: $color_neutral_400;
$text_disabled_color: $color_neutral_400;
$label_color: $color_neutral_900;
$red_error_color: $color_additional_red_500;

//---------List preset----------//
$background_backdrop_color: $color_white_3;
$background_row_color: $color_white_3;
$background_row_even_color: $color_neutral_50;
$border_table_color: $color_neutral_200;
$border_table_mobile_color: $color_neutral_100;
$nodata_color: $color_neutral_300;

//---------Switch preset----------//
$accent_switch_color: $color_additional_green_500;
$circle_slider_color: $color_white_3;
$background_unchecked_color: $color_neutral_300;

//---------Toggler preset----------//
$background_slider_color: $color_neutral_100; // background slider, background tag

//---------Backdrop preset----------//
$background_backdrop_color: $color_white_3;

//---------List selectors (roles)----------//
$background_light_color: $color_neutral_100;
$text_color_light: $color_neutral_600;

$color_gray_1: #e4e3e4; // background, color
$color_gray_2: #b7b7b7; // borders
$color_gray_3: #959a9e; // borders for inputs and selects
$color_gray_4: #b4b3b4; // radio span background and borders, table borders, disabled button
$color_gray_5: #e6e8e9; // list component (borders)
$color_gray_6: #f8f8f8; // list component
$color_gray_7: #e9e9e9; // list component (background cell)
$color_gray_8: #b7b7b7; // list component (icon)

$color_black_1: #1b1b1b;
$color_black_2: #292929;
$color_black_3: #525252;
$color_black_4: #232323; // list component

$color_accent: #ed5950; // buttons, icons
$color_accent_hover: #ec8e8e;

$color_blue_1: #007bff; // links

$color-darkgray1: #232323;
$color-darkgray2: #1d1d1f;

$color-darkgray1: #232323;
$color-darkgray2: #1d1d1f;
$color-gray: #888888;
$color-white: #fff;
$color-offwhite: #f5f5f7;
$color-offwhite2: #fafafa;
$color-blue1: #00a3e0;
$color-blue2: #0070c9;
$color-blue3: #1786ff;
$color-graye7: #bbbbbb;
$color-red: #f60b0b;
$color-red2: #d01313;
$color-orange: #ed970f;
$color-lightgreen: #00b23b;
$color-grayee: #eeeef4;
$color-transparent: transparent;
$color-darkgray33: #727272;

$color-black04: #040404;
$color-black18: #181818;
$color-black28: #28282a;
$color-black8c: #727272;
$color-yellow: #fbbe36;
$color-yellow2: #cf9312;
$background_color_1: #86868b;
$background_color_2: #dadadc;
