.tableBorder {
  border-right: 1px solid #ece7e7;
  border-left: 1px solid #ece7e7;
}

.rightBorder {
  border-right: 1px solid #ece7e7;
}
.discountBody {
  width: 100%;
}
