@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .subtitles {
      margin-top: 1.5rem;
    }
  }
}

.main_section {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .form_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .fields {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
      margin-bottom: 1rem;

      .row {
        display: flex;
        width: 100%;
      }

      .field {
        position: relative;
        flex: 1 1 calc(50% - 0.75rem);
      }

      .switcher {
        width: auto;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
          font-size: 12px;
          color: #1d1d1f;
        }
      }
    }

    .table_container {
      position: relative;
      margin-bottom: 1rem;
    }

    .categories_container {
      position: relative;
    }

    .buttons {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;

      .button {
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.cell {
  position: relative;
  padding-left: 50%;
  text-align: left;
  min-height: 2em;
  overflow-wrap: break-word;
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.category_option {
  font-size: 14px;
  display: inline-flex;
  flex: 0 0 33.3333%;
  align-items: center;
  margin-bottom: 1rem;
}

.section_title {
  border-bottom: 1px solid $color_gray_2;
  padding-bottom: 1rem;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .main_container {
    padding: 0;
  }

  .main_section {
    margin-left: 0;

    .content {
      padding: 0;

      .form_container {
        .fields {
          flex-direction: column;

          .field {
            max-width: none;
          }
        }
      }
    }
  }

  .category_option {
    flex: 0 0 50%;
  }
}
