@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.actions {
  padding: 0;
  border-left: 1px solid $color_gray_1;

  .action {
    &:first-child {
      order: -1;
    }

    &:last-child {
      order: 1;
    }
  }
}

.actions_cell {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 0;
  align-items: center;

  &:has(> *:nth-child(2)):before {
    align-self: stretch;
    content: '';
    border-bottom: 1px solid $color_gray_1;
  }
}

.print_type {
  padding: 0;
  border-left: 1px solid $color_gray_1;

  .receipt_type {
    padding: 1rem 0;

    &:first-child {
      order: -1;
    }

    &:last-child {
      order: 1;
    }
  }
}

.print_type_cell {
  display: flex;
  height: 100%;
  padding: 0;
  flex-direction: column;
  justify-content: space-evenly;

  &:has(> *:nth-child(2)):before {
    align-self: stretch;
    content: '';
    border-bottom: 1px solid $color_gray_1;
  }
}

@media (max-width: 768px) {
  .actions_cell {
    padding-left: 46%;
  }

  .print_type {
    .receipt_type {
      text-align: center;
      padding-left: 50%;
    }
  }
}
