@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .title {
      color: $color_black_1;
      font-size: 20px;
      font-weight: 600;
      padding: $padding-default 0;
      margin: 0 0 20px 0;
      border-bottom: 1px solid $color_gray_2;
    }

    .add_tip {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0.75rem;
      margin-bottom: 1rem;

      .section {
        max-width: 520px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 300px;

        .tip_name_input,
        .tip_percent_input {
          min-width: 300px;
          margin-bottom: 0 !important;
        }
      }

      .add_button {
        margin-top: 24px;
      }
    }

    .tips_list {
      margin-bottom: 1rem;
    }

    .update_button {
      max-width: 170px;
    }
  }
}

@media (max-width: 1100px) {
  .add_tip {
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .main_container {
    padding: 0;

    .content {
      .add_tip {
        margin-bottom: 2rem;

        .section {
          width: 100%;
          max-width: 100%;

          .tip_name_input,
          .tip_percent_input {
            min-width: 100% !important;
            width: 100% !important;
          }
        }

        .add_button {
          width: 100% !important;
          margin-top: 0;

          & button {
            width: 100% !important;
          }
        }
      }
    }
  }
}
