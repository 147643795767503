@import '../../../assets/styles/colors';

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.4;
  z-index: 99;
}
