@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.graph_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;

  .chart_wrapper {
    position: relative;

    .subtitle {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }

  .time_period {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .info_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-weight: 400;
      color: $color_black_3;
      font-size: 14px;
      margin-right: 20px;

      .info {
        font-size: 14px;
        font-weight: 400;
        color: $color_black_1;
      }

      .info_icon {
        font-size: 14px;
        width: 18px;
        height: 18px;
        color: $color_black_1;
        border-radius: 50%;
        margin-left: 8px;
        position: relative;
        cursor: pointer;
      }

      .info_tooltip {
        position: absolute;
        max-width: 200px;
        min-width: 200px;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-black_2;
        color: white;
        padding: 8px 8px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        z-index: 10;
        opacity: 0.9;
        box-shadow: 0px 4px 8px $color-gray_2;
      }
    }

    .value {
      font-weight: bold;
      margin-bottom: 4px;
      font-size: 16px;
    }
  }
}
