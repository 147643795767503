.full_name {
  color: #007bff;
  font-weight: 600;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.row_actions_button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:first-child {
    margin-right: 6px;
  }

  svg {
    color: #ed5950;
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 768px) {
  .full_name {
    color: #fff;
    text-decoration: underline;
  }
}
