@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';


.footer {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color_black_2;
  background-color: $color_white_1;
  font-size: 14px;
  text-decoration: none;
  padding: 0 10px;
  flex-shrink: 0;

  & .infoSection {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & .infoLinks {
      list-style-type: none;

      a {
        font-family: "SFProDisplayMedium", sans-serif;
        cursor: pointer;
        text-decoration: none;
        color: $color_black_2;

        &:first-child {
          margin-right: 10px;
        }
      }

    }
  }

  & .copyright {
    font-family: "FontAwesome", sans-serif;
    padding: 20px 10px;
  }
}


@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
  }
}