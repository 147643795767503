@import '../../../../assets/styles/colors';

.drag_icon {
  width: 100%;
  position: relative;

  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.assign_item_link {
  color: $accent_color;
  cursor: pointer;
  font-weight: 700;
}

@media (max-width: 768px) {
  .drag_icon {
    display: none;
  }
}
