@import '../../../assets/styles/colors';

$background_color: $color_accent_400;
$main_color: $color_accent_200;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px 18px;
  margin: 0 -5%;
}

.dot_flashing {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: $background_color;
  color: $background_color;
  animation: dot_flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot_flashing::before,
.dot_flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot_flashing::before {
  left: -10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: $background_color;
  color: $background_color;
  animation: dot_flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot_flashing::after {
  left: 10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: $background_color;
  color: $background_color;
  animation: dot_flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot_flashing {
  0% {
    background-color: $background_color;
  }
  50%,
  100% {
    background-color: $main_color;
  }
}
