@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/fonts';

.item_details {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 96px);
  box-sizing: border-box;

  .header {
    width: 100%;
    padding: 20px;
    background: $color_white_3;
    border-bottom: 1px solid $color_accent_300;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px;
    padding: 20px;
    background: $color_white_3;
    border: 1px solid $color_accent_300;
    border-radius: 8px;
    height: 100%;
    flex-grow: 1;

    .title {
      width: 100%;
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $color_neutral_900;
      text-align: left;
      margin-bottom: 6px;
    }

    .text {
      width: 100%;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $color_neutral_500;
      text-align: left;
    }

    .wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;

      .parameters {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 1000px;
        flex: 3;
        margin-right: 20px;

        .field {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 14px;

          .label {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $color_neutral_800;
            text-align: left;
            margin-right: 10px;
          }

          .info {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $color_neutral_500;
            text-align: left;
            white-space: normal;
          }

          .multiple_info {
            & span {
              background: $color_accent_100;
              border-radius: 36px;
              padding: 4px 12px;
              margin-right: 10px;
            }

            & span {
              color: $color_neutral_700;
            }

            & span:last-child {
              margin-right: 0;
            }
          }
        }

        .column_field {
          align-items: flex-start;
          flex-direction: column;

          .label {
            margin-bottom: 6px;
          }
        }
      }

      .item_image {
        width: 100%;
        height: 100%;
        flex: 0;

        .image_content {
          display: flex;
          width: 100%;
          height: 100%;
          max-width: 340px;
          min-width: 340px;
          max-height: 200px;
          min-height: 200px;
          border: 1px solid $color_accent_300;
          border-radius: 6px;
          overflow: hidden;

          .image_wrapper {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: $color_accent_100;
            cursor: auto;

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .no_image_stub {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              text-align: center;
              font-size: 14px;
              cursor: auto;

              .no_image_icon {
                font-size: 24px;
                color: $color_gray_2;
              }

              .stub_description {
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: $color_neutral_400;
                padding: 5px;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left_side {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .button {
          border-radius: 6px;

          & button {
            background: $color_additional_red_500;
            color: $color_white_3;
          }
        }
      }

      .right_side {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .button {
          border-radius: 6px;
        }

        .button:first-child {
          margin-right: 8px;
        }
      }

      .button {
        width: auto;
        min-width: auto;
        min-height: auto;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 1315px) {
  .wrapper {
    flex-direction: column;

    .parameters {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .item_image {
      margin-top: 20px;
      flex: 1;
    }
  }
}

@media (max-width: 950px) {
  .field {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;

    .multiple_info {
      display: flex;
      flex-wrap: wrap;

      & span {
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
}

@media (max-width: 464px) {
  .buttons {
    justify-content: flex-start;

    .left_side {
      .button {
        margin-right: 8px;
      }
    }
  }
}
