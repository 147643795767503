@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.card_container {
  width: 180px;
  height: 192px;
  display: flex;
  flex-direction: column;
  border: 1px solid $border_color;
  border-radius: 6px;
  background-color: $color_white_3;

  .head {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border_color;
    gap: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    .name {
      max-height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }

    .cost_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $text_placeholder_color;
        font-weight: 400;
      }

      .tag {
        $text-color: var(--text-color);
        padding: 0 6px;
        min-width: 40px;
        height: 20px;
        border-radius: 14px;
        text-align: center;
        color: rgb($text-color);
        background-color: rgba($text-color, 0.15);
      }
    }
    .onpos_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $text_placeholder_color;
        font-weight: 400;
      }
    }
  }
  .image {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: $color_neutral_100;
    height: 100%;
    border-radius: 0 0 5px 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .no_items {
      svg {
        width: 24px;
        height: 24px;
        margin-bottom: 0.5rem;

        path {
          stroke: $color_neutral_300;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
        text-transform: unset;
      }
    }

    .menu_item_icon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 100px;
        height: 80px;
        margin-bottom: 0.5rem;
        fill: $color_neutral_300;
      }
    }
  }
}

.no_data {
  position: relative;
  height: 192px;
  width: 100%;

  .no_items {
    svg {
      width: 60px;
      height: 60px;
      margin-bottom: 1rem;

      path {
        stroke: $color_neutral_300;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.skeleton_box {
  @include skeleton_box();
}
