@import '../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    .list_controller {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.75rem;

      .search {
        min-width: 280px;
      }
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;
  }
}

.customer_section {
  min-height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .form_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .fields {
      .name_fields {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        &.block {
          margin-bottom: 1rem;
        }
      }

      .field {
        position: relative;
        width: 100%;
        flex: 1 1 calc(50% - 0.75rem);

        .dynamic_form_button {
          width: 170px;
        }

        .picker_wrapper {
          width: 100%;
        }

        &.block {
          margin-bottom: 1rem;
        }
      }
    }

    .phone_container {
      max-width: 50%;
    }

    .buttons {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
  }
}

@media (max-width: 768px) {
  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 500px) {
  .customer_section {
    .form_container {
      .fields {
        .name_fields {
          flex-direction: column;
        }
        .field {
          .dynamic_form_button {
            width: 100%;
          }
          .phone_container {
            max-width: 100%;
          }
        }
      }
    }
  }
}
