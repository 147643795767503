@import '../../../assets/styles/colors';

$input-height: 40px;
$icon-size: 1.5rem;

$background_color: $color_white_3;
$border_color: $color_neutral_300;
$border_focus_color: $color_neutral_400;
$text_color: $color_neutral_900;
$text_placeholder_color: $color_neutral_400;

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .button {
    cursor: pointer;
    position: absolute;
    text-align: center;
    padding: 0 10px 0 12px;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;

    .icon {
      width: 16px;
      height: 100%;
    }
  }

  .clear {
    right: 0;
    left: auto;
    padding-right: 6px;
  }

  input {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: $input-height;
    padding: 10px 34px 10px 40px;
    width: 100%;
    color: $text_color;
    border: 1px solid $border_color;
    border-radius: 6px;
    background-color: $background_color;
    background-image: none;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text_placeholder_color;
    }

    &:active,
    &:focus {
      border: 2px solid $border_focus_color;
      outline: none;
    }
  }
}
