@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;

  .subtitle {
    font-size: 16px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .content {
    position: relative;
    max-height: 500px;
    height: auto;
    overflow-y: auto;
    padding: 0 10px;

    .item {
      border-top: 1px solid $color-gray_1;
      border-bottom: 1px solid $color-gray_1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px 0;
      margin-bottom: 10px;
      width: 100%;

      .name {
        color: $color-black_3;
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        width: 33%;
      }

      .price {
        color: $color-black_1;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        width: 33%;
      }

      .quantity {
        color: $color-black_1;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        width: 33%;
      }
    }

    .no_data {
      color: $color-gray_3;
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
    }
  }
}
