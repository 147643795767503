@import '../../../assets/styles/colors';

.loader {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid $border_color;
  border-top: 6px solid $light_accent_color;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
