@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .info_container {
    position: relative;
    flex: 1;
    padding: 15px;

    & .form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      & .main_info {
        & .subtitle {
          font-family: 'SFProDisplayMedium', sans-serif;
          color: $color_black_1;
          font-size: 16px;
          padding: 10px 0;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        & .section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          // .autocomplete {
          //   width: 100%;

          //   & input {
          //     color: $color_gray_3;
          //   }

          //   & input,
          //   select {
          //     width: 100% !important;
          //     font-size: 16px;
          //     padding: 10px;
          //     border-radius: 0;
          //     border: 1px solid $color_gray_2;
          //   }
          // }
        }

        & .submitButton {
          max-width: 400px;
          margin: 30px 0;

          & button {
            width: 100%;
          }
        }
      }

      & .add_logo {
        width: 150px;
        margin-left: 2.5rem;
        margin-top: 4rem;
        box-shadow: 0px 0px 1px 0px $color_gray_3;

        .logo_container {
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          background-color: $color_gray_3;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          cursor: pointer;
        }

        .plus_icon {
          font-size: 24px;
          color: $color_gray_2;
        }

        .logo_image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }

        .logo_footer {
          width: 100%;
          background-color: inherit;
          color: $color_gray_3;
          text-align: center;
          padding: 5px 0;
          font-size: 14px;
          border: 1px solid $color_gray_2;
          border-top: 0;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          cursor: pointer;

          & p {
            font-family: 'SFProDisplayMedium', sans-serif;
            padding: 5px;
            margin: 0;
          }
        }
      }
    }
  }
}

.selector_group select {
  border-bottom: none !important;
}

@media (max-width: 768px) {
  .information {
    padding: 0;
  }

  .form {
    flex-direction: column-reverse !important;
  }

  .main_info {
    width: 100% !important;
  }

  .add_logo {
    margin-left: 0 !important;
    margin-top: 24px !important;
  }
}
