@import '../../../assets/styles/colors';

.no_items {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 96px;
    height: 96px;
    margin-bottom: 2rem;

    * {
      stroke: $border_table_color;
    }
  }

  p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: $text_disabled_color;
    text-align: center;
    margin: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
