@import '../../../assets/styles/colors';

.label {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px !important;
  color: $text_color;

  .starlet {
    color: $accent_color !important;
  }
}
