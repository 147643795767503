@font-face {
  font-family: 'FontAwesome';
  src:
    url('../fonts/fontawesome-webfont.woff2') format('woff2'),
    url('../fonts/fontawesome-webfont.woff') format('woff'),
    url('../fonts/fontawesome-webfont.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplayBold';
  src:
    url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Bold.woff') format('woff'),
    url('../fonts/SFProDisplay-Bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplayHeavy';
  src:
    url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Heavy.woff') format('woff'),
    url('../fonts/SFProDisplay-Heavy.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplayLight';
  src:
    url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Light.woff') format('woff'),
    url('../fonts/SFProDisplay-Light.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplayMedium';
  src:
    url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Medium.woff') format('woff'),
    url('../fonts/SFProDisplay-Medium.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplayRegular';
  src:
    url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Regular.woff') format('woff'),
    url('../fonts/SFProDisplay-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplaySemibold';
  src:
    url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Semibold.woff') format('woff'),
    url('../fonts/SFProDisplay-Semibold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextBold';
  src:
    url('../fonts/SFProText-Bold.woff2') format('woff2'),
    url('../fonts/SFProText-Bold.woff') format('woff'),
    url('../fonts/SFProText-Bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextHeavy';
  src:
    url('../fonts/SFProText-Heavy.woff2') format('woff2'),
    url('../fonts/SFProText-Heavy.woff') format('woff'),
    url('../fonts/SFProText-Heavy.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextLight';
  src:
    url('../fonts/SFProText-Light.woff2') format('woff2'),
    url('../fonts/SFProText-Light.woff') format('woff'),
    url('../fonts/SFProText-Light.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextMedium';
  src:
    url('../fonts/SFProText-Medium.woff2') format('woff2'),
    url('../fonts/SFProText-Medium.woff') format('woff'),
    url('../fonts/SFProText-Medium.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextRegular';
  src:
    url('../fonts/SFProText-Regular.woff2') format('woff2'),
    url('../fonts/SFProText-Regular.woff') format('woff'),
    url('../fonts/SFProText-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProTextSemibold';
  src:
    url('../fonts/SFProText-Semibold.woff2') format('woff2'),
    url('../fonts/SFProText-Semibold.woff') format('woff'),
    url('../fonts/SFProText-Semibold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/Inter-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/Inter-ThinItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/Inter-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/Inter-ExtraLightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Inter-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Inter-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Inter-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Inter-ExtraBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Inter-Black.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Inter-BlackItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/InterDisplay-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/InterDisplay-ThinItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/InterDisplay-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/InterDisplay-ExtraLightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/InterDisplay-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/InterDisplay-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/InterDisplay-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/InterDisplay-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/InterDisplay-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/InterDisplay-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/InterDisplay-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/InterDisplay-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/InterDisplay-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/InterDisplay-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/InterDisplay-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/InterDisplay-ExtraBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/InterDisplay-Black.woff2') format('woff2');
}
@font-face {
  font-family: 'InterDisplay';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/InterDisplay-BlackItalic.woff2') format('woff2');
}
