@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.items {
  padding: 20px;

  .list_controller {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .filter_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .role_selector {
        min-width: 190px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .search {
        min-width: 280px;
        margin-right: 10px;
        margin-bottom: 0.5rem;
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;

      .add_button {
        margin-right: 10px;

        & button {
          background: $color_accent;

          &:hover {
            background: $color_accent_hover;
          }
        }
      }

      .delete_button {
        & button {
          color: $color_accent;
          background: $color_gray_1;
          border: none;
          margin-right: 14px;

          &:hover {
            background: $color_gray_4;
          }
        }
      }
    }
  }
}

@media (max-width: 912px) {
  .filter_container {
    margin-bottom: 20px;

    .role_selector {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    .search {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 450px) {
  .buttons {
    width: 100%;
    flex-direction: column;

    .add_button {
      margin-right: 0 !important;
    }

    & button {
      width: 100%;
      margin: 0 !important;
      padding: 0 !important;
      margin-bottom: 10px !important;
    }
  }
}
