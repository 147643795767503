@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(59, 59, 59, 0.603);
  z-index: 1000;

  &_title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &_content {
    min-width: 400px;
    border-radius: 8px;
    padding: 1rem 4rem;
    background-color: $color_white_1;
    color: $color_black_1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  &_cross_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    & svg {
      height: 24px;
      width: 24px;

      & path {
        fill: $color_black_1;
      }
    }

    &:hover {
      & svg {
        & path {
          fill: $color_accent;
        }
      }
    }
  }

  &_message {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
  }

  &_actions {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  &_button {
    @include button-style(100%);
    border-top: 1px solid $color_gray_2;
    font-weight: 500;

    &:hover {
      background: $color_white_1;
      color: $color_accent;
    }
  }
}

@media (max-width: 768px) {
  .modal_content {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .modal_content {
    width: 96%;
  }
}
