@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.roles {
  padding: 0;

  .header {
    padding: 15px;
    border-bottom: 1px solid $color_neutral_300;
    background-color: $color_white_3;
  }

  .create_role {
    padding: 15px;
    max-width: 800px;
    margin-bottom: 40px;

    .role_name {
      width: 100%;
      margin-bottom: 30px;
    }

    .select_module,
    .select_employee {
      margin-bottom: 30px;

      .subtitle {
        font-size: 14px;
        font-weight: 600;
        color: $text_color;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
      }

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-wrap: wrap;

        .modules_list,
        .employee_list,
        .selected_modules,
        .selected_employees {
          position: relative;
          background-color: $background_color;
          min-width: 180px;
          height: 200px;
          overflow-y: scroll;
        }

        .module,
        .employee,
        .right_item {
          font-size: 14px;
          padding: 10px;
          background-color: $background_color;
          cursor: pointer;
          color: $text_color;
        }

        .right_item {
          cursor: auto;
        }

        .modules_list,
        .employee_list,
        .selected_modules,
        .selected_employees {
          width: 32%;
          border-radius: 6px;
          border: 1px solid $main_border_color;
        }

        .buttons {
          display: flex;
          flex-direction: column;
          padding: 12px;
          gap: 0.75rem;
        }
      }
    }

    .submit_buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }
  }

  .assign_list {
    padding: 15px;
  }
}

.module_selected,
.employee_selected {
  color: $invert_text_color !important;
  background: $accent_color !important;
}

.roles_edit_modal {
  z-index: 1000;

  .modal_content {
    padding: 14px;
    box-sizing: border-box;
    width: 50vw;
  }

  & .edit_title_modal {
    color: $text_color;
    font-size: 20px;
    font-weight: 600;
    padding: $padding-default 0;
    margin: 0 0 20px 0;
    text-align: center;
  }

  & div {
    background: $color_gray_1;
    border: $border_color;
    border-radius: 4px;
    z-index: 2000;
    color: $text_color;

    .edit_wrapper_modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      .role_name_modal {
        width: 100%;
        padding: 10px;
        text-align: left;
      }

      .role_name_modal label {
        text-wrap: nowrap;
      }

      .role_name_modal input {
        color: $text_color;
        width: 100%;
        font-size: $font-size-default;
        padding: $padding-default;
        border-radius: 0;
        border: 1px solid $border_color;
        margin-top: 10px;
      }

      .select_module,
      .select_employee {
        width: 100%;
        margin-bottom: 30px;

        & label {
          display: block;
          width: 100%;
          padding: 10px;
          text-align: left;
        }

        .wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: $padding-default;

          .modules_list,
          .employees_list {
            width: 100%;
            height: 200px;
            overflow-y: scroll;
            border: 1px solid $border_color;
            scrollbar-width: thin;
            scrollbar-color: $color_accent $color_gray_1;

            .module,
            .employee {
              padding: 10px;
              cursor: pointer;
              color: $text_color;
              background: $color_white_1;
            }
          }
        }
      }
    }
  }
}

.roles_assign_employees_modal,
.roles_assign_modules_modal,
.delete_message_modal {
  z-index: 1000;

  .modal_content {
    padding: 14px;
    box-sizing: border-box;
    width: 50vw;
  }

  & .assign_title_modal {
    color: $text_color;
    font-size: 20px;
    font-weight: 600;
    padding: $padding-default 0;
    margin: 0 0 20px 0;
    text-align: center;
  }

  & div {
    background: $color_gray_1;
    border: $border_color;
    border-radius: 0;
    z-index: 2000;
    color: $text_color;

    .assign_wrapper_modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      .select_module,
      .select_employee {
        width: 100%;
        margin-bottom: 30px;

        & label {
          display: block;
          width: 100%;
          padding: 10px;
          text-align: left;
        }

        .wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: $padding-default;

          .modules_list,
          .employees_list {
            width: 100%;
            height: 200px;
            overflow-y: scroll;
            border: 1px solid $border_color;
            scrollbar-width: thin;
            scrollbar-color: $color_accent $color_gray_1;

            .module,
            .employee {
              padding: 10px;
              cursor: pointer;
              color: $text_color;
              background: $color_white_1;
            }
          }
        }
      }
    }

    .save_button {
      @include button-style(200px);
      width: 100%;
      box-shadow: none;

      &:hover {
        background-color: $color_accent_hover;
      }
    }
  }
}

@media (max-width: 575px) {
  .roles {
    padding: 0;

    .create_role {
      .select_module,
      .select_employee {
        .wrapper {
          flex-direction: column;

          .modules_list,
          .employee_list,
          .selected_modules,
          .selected_employees {
            width: 100%;
          }

          .buttons {
            width: 50%;
          }

          .selected_modules,
          .selected_employees {
            width: 100%;
          }
        }
      }
    }
  }
}
