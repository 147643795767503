@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

$input-height: 40px;
$placeholder-transition: all 0.3s ease;
$icon-size: 1.5rem;

$background_color: $color_white_3;
$border_color: $color_neutral_300;
$border_focus_color: $color_neutral_400;
$border_disabled_color: $color_neutral_400;
$icon_color: $color_neutral_400;
$text_color: $color_neutral_900;
$text_placeholder_color: $color_neutral_400;
$text_disabled_color: $color_neutral_400;
$label_color: $color_neutral_900;
$red_error_color: $color_additional_red_500;

@mixin placeholder-active {
  top: 42.5%;
  font-weight: 600;
  color: $text_color;
  line-height: 14px;
  transform: translateY(-85%);
}

.wrapper {
  width: 100%;

  .container {
    position: relative;

    .label {
      display: inline-block;
      color: $label_color;
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.4s ease;
    }

    &:has(input:disabled) {
      .label {
        color: $text_disabled_color;
      }
    }

    .inner_placeholder {
      position: absolute;
      font-size: 14px;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: $placeholder-transition;
      pointer-events: none;
      //color: $text_placeholder_color;
      color: #94A3B8;
      margin: 0;

      &_active {
        @extend .inner_placeholder;
        @include placeholder-active;
      }
    }

    .asterisk::after {
      //color: $red_error_color;
      color: #0F172A;
      content: '*';
    }

    .field {
      display: block;
      width: 100%;
      height: $input-height;
      color: $text_color;
      background-color: $background_color;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      border: $border_color 1px solid;
      border-radius: 6px;
      box-shadow: none;
      background-clip: padding-box;
      transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

      &[type='color'] {
        appearance: none;
        border: 1px solid $border_color;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
      }
      &[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      &[type='color']::-webkit-color-swatch {
        border: none;
      }

      &:focus {
        border-width: 2px;
        border-color: $border_focus_color;
        box-shadow: none;
        outline: none;
      }

      &:not(select) {
        appearance: none;
      }

      &:disabled {
        border-color: $border_disabled_color;
      }
    }

    .with_prefix {
      padding-left: 32px;
    }
    .with_suffix {
      padding-right: 32px;
    }

    %icon {
      position: absolute;
      color: $icon_color;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      & > * {
        display: inherit;
        width: $icon-size;
        height: $icon-size;
      }
    }

    &:has(label) {
      %icon {
        transform: translateY(0);
      }
    }

    .prefix_icon {
      @extend %icon;
      left: 0.8rem;
    }

    .suffix_icon {
      @extend %icon;
      right: 0.8rem;
    }

    &:focus-within {
      &:has(.field:-webkit-autofill):has(.inner_placeholder),
      &:has(.inner_placeholder) {
        .field {
          font-size: 14px;
          padding-top: calc($input-height / 2);
          height: $input-height;
        }

        .inner_placeholder {
          @include placeholder-active;
        }
      }
    }

    &:has(.inner_placeholder_active) {
      .inner_placeholder_active {
        @include placeholder-active;
      }

      .field {
        height: $input-height;
        padding-top: calc($input-height / 2);
      }
    }

    &:has(.inner_placeholder) {
      .field {
        height: $input-height;
        padding-top: calc($input-height / 2);
      }
    }

    &:has(.with_prefix) {
      .inner_placeholder,
      .inner_placeholder_active {
        left: 3.05rem;
      }
    }

    &:has(.with_suffix) {
      .inner_placeholder,
      .inner_placeholder_active {
        right: 3.05rem;
      }
    }
  }
  .error_container {
    padding: 0;

    .error {
      color: $red_error_color;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 2px;
    }
  }

  &:has(.error_container) {
    .container {
      .field {
        border-color: $red_error_color;
      }
    }
  }
}
