.chart_wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .chart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    width: auto;
    height: auto;
  }
}
