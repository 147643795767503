@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.wrapper {
  width: 100%;
  height: 100vh;
  background-color: $color_white_3;
}

.reset_password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 530px;
  border: 1px solid $color_gray_2;

  .logo {
    padding: 0 4rem;
    margin: 3rem 0 2rem 0;
    user-select: none;
  }

  .title {
    color: $color_black_3;
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }

  .field {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.return_login {
  width: 100%;

  a {
    color: $color_accent;
    text-decoration: none;

    &:visited {
      color: $color_accent;
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .reset_password {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .reset_password {
    width: calc(100% - 20px);
  }
}
