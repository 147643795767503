@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .add_note {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;

      .input_field {
        max-width: 520px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;
      }

      .add_button {
        margin-top: 24px;
      }
    }

    .list_container {
      margin-bottom: 1rem;
    }

    .update_button {
      width: 170px;
    }
  }
}

@media (max-width: 768px) {
  .main_container {
    .content {
      .add_note {
        margin-bottom: 2rem;

        .input_field,
        .add_button {
          max-width: 100%;
          width: 100%;
          margin-top: 0;
        }
      }

      .update_button {
        width: 100%;
      }
    }
  }
}
