.section {
  .label {
    font-size: 14px;
    line-height: 1.5rem;
    text-transform: capitalize;
  }

  .additional_info {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;

    .field {
      width: 100%;
    }
  }

  .selector {
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .section {
    .additional_info {
      flex-wrap: wrap;
    }
  }
}
