@import '../../../assets/styles/colors';

.no_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #F1F5F9;

  .image {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
    object-fit: cover;
    z-index: 10;
  }

  svg {
    margin-bottom: 2rem;

    path {
      stroke: $border_table_color;
    }
  }

  h5 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #94A3B8;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    padding: 0;
  }
}
