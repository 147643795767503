@import './fonts.css';
@import 'colors';
@import 'fonts';
@import 'mixins';

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter', sans-serif;
  }

  input {
    font-family: 'Inter', sans-serif;
  }

  select {
    font-family: 'Inter', sans-serif;
  }

  p {
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: $color_accent_500;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $color_accent_600;
  }
  ::-webkit-scrollbar-thumb:active {
    background: $color_accent_700;
  }
}

select:focus {
  outline: none !important;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// hiding labels for country phone number inputs
.special-label {
  display: none !important;
}

.react-tel-input {
  width: 100% !important;
}

// styling country phone number inputs
.react-tel-input .form-control {
  width: 100% !important;
}

input[type='tel'] {
  width: 100% !important;
  border: 1px solid $color_gray_3;
  border-top: none;
}

// Employee page (Timesheet section)
.react-datepicker-wrapper {
  width: 200px;
  // height: 45px !important;
  // margin-right: 20px;

  .react-datepicker__input-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.react-datepicker-popper {
  z-index: 1000;
}

@media (max-width: 768px) {
  // Employee page (Timesheet section)
  .react-datepicker-wrapper {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 1em;

    input {
      width: 100% !important;
      height: 46px !important;
    }
  }
}
