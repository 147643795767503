.main_container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .wrap {
    position: relative;
    display: flex;
    height: 0;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.3s;
  }
}
