@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.item {
  padding: 20px;
}

.role_selector {
  min-width: 190px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.search {
  min-width: 280px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.new_category_name {
  min-width: 190px;
  width: 300px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  & input {
    width: 100%;
    padding: 13px;
    color: #232323;
    border: #b3bcc5 1px solid;
    border-radius: 0;
    background-color: inherit;
    background-image: none;
    font-size: 14px;
    font-family: 'SFProDisplayMedium', sans-serif;
    outline: none;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  //margin-top: 1rem;

  .add_button {
    margin-right: 10px;
    & button {
      background: $color_accent;

      &:hover {
        background: $color_accent_hover;
      }
    }
  }

  .delete_button {
    & button {
      color: $color_accent;
      background: $color_gray_1;
      border: none;

      &:hover {
        background: $color_gray_4;
      }
    }
  }
}

.list_controller {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .filter_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
}

.employee_edit_section {
  min-height: 100%;

  .content {
    padding: 20px 30px;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .form_container {
      margin-top: 1.5rem;

      .fields {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;

        .field {
          position: relative;
          width: 100%;
          max-width: 50%;
          padding-right: 15px;
          padding-left: 15px;
          flex: 0 0 50%;
        }
      }

      .buttons {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .button {
          margin-right: 1.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .role_selector {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .new_category_name {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .employee_edit_section {
    margin-left: 0;

    .content {
      padding: 0;

      .form_container {
        .fields {
          flex-direction: column;

          .field {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// Modal Window for Edit Category

.edit_category_modal,
.assign_items_modal {
  border-radius: 4px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  .modal_content {
    background: $color_white_1;

    .modal_header {
      position: relative;
      border-bottom: 1px solid $color_gray_2;
      padding: $padding-default;

      .modal_title {
        color: $color_black_1;
        font-size: 20px;
        font-weight: 600;
        padding: $padding-default 0;
        margin: 0;
        text-align: center;
      }

      .cross_icon {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 14px;
        right: 10px;
        padding: 4px;
        cursor: pointer;
        background: $color_gray_1;

        & svg {
          height: 24px;
          width: 24px;

          & path {
            fill: $color_black_1;
          }
        }
      }
    }

    .modal_body {
      padding: 10px 20px;

      .edit_category_name {
        & label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: 14px;
          font-weight: 600;
          font-family: 'SFProDisplayMedium', sans-serif;
          color: $color_black_1;

          & span {
            color: $color_accent;
          }
        }

        & input {
          width: 100%;
          padding: 13px;
          color: $color_black_1;
          border: $color_gray_2 1px solid;
          border-radius: 0;
          background-color: inherit;
          background-image: none;
          font-size: 14px;
          font-family: 'SFProDisplayMedium', sans-serif;
          outline: none;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      padding: 10px 20px;

      .update_button,
      .cancel_button {
        @include button-style(200px);
        font-weight: 700;
      }

      .update_button {
        background: $color_accent;
        color: $color_white_1;
        margin-right: 10px;

        &:hover {
          background: $color_accent_hover;
        }
      }

      .cancel_button {
        background: $color_gray_1;
        color: $color_accent;

        &:hover {
          background: $color_gray_2;
        }
      }
    }
  }
}

.assign_items_modal {
  .search {
    margin-bottom: 1rem;
  }

  .menu_items {
    .menu_list_of_items {
      height: 200px;
      //border: 2px solid orange;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: $color_accent $color_gray_1;
    }

    .menu_item,
    .menu_select_all {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color_gray_2;

      .menu_item_name {
        font-size: 14px;
        font-weight: 600;
        color: $color_black_1;
        margin-left: 4px;
      }
    }

    .menu_select_all {
      margin-bottom: 30px;
    }
  }
  .update_button,
  .cancel_button {
    width: 160px !important;
  }
}
