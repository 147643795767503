@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    .list_controller {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.75rem;

      .role_selector {
        min-width: 190px;
      }

      .search {
        min-width: 280px;
      }
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;
  }
}

.item {
  padding: 20px;
}

.modal {
  max-width: 450px;

  .employee_edit_container {
    min-height: 100%;
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 2rem;
    }

    .form_container {
      position: relative;

      .fields {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .field {
          position: relative;
          width: 100%;
          margin-bottom: 0.75rem;
        }

        .selector_field {
          position: relative;
        }
      }

      .action_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.75rem;
      }
    }
  }

  .employee_info_container {
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .form_section {
      margin-bottom: 1rem;

      .primary_info {
        margin-bottom: 2rem;

        p {
          color: $color_accent_500;
          margin: 0;
        }
      }
      .additional_info {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        .info_row {
          display: flex;
          justify-content: space-between;

          .info_value {
            font-weight: 500;
            color: $color_accent_500;
          }
        }
      }
    }

    .action_buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.75rem;

      .delete_btn {
        min-width: 72px;
        margin-right: auto;
      }

      .close_btn {
        min-width: 72px;
      }
    }
  }
}

@media (max-width: 768px) {
  .role_selector {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .employee_edit_section {
    margin-left: 0;

    .content {
      padding: 0;

      .form_container {
        .fields {
          .row {
            flex-direction: column;
          }

          .selector_field {
            width: 100%;
          }
        }
      }
    }
  }
}
