@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list_container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    padding: 15px;

    .list {
      flex: 1;
      margin-bottom: 1rem;
    }

    .save_button {
      flex: 0;
      width: 170px;
    }
  }
}
