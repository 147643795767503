@import '../../../../../assets/styles/grid';
@import '../../../../../assets/styles/tables';
@import '../../../../../assets/styles/utilities';

.download-area {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  gap: 10px;
}
.title {
  font-size: 28px;
  font-weight: 400;
}
