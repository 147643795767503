@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .title_container {
        display: flex;
        gap: 0.5rem;
        align-items: flex-end;

        .title {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .cards_controller {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.75rem;

      .role_selector {
        min-width: 190px;
      }

      .search {
        min-width: 280px;
      }
    }
  }

  .cards_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .sub_title {
      border-bottom: 1px solid $border_color;
      padding-bottom: 4px;
    }
  }
}
