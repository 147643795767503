@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.assign_items_modal {
  max-width: 450px;

  .modal_content {
    min-height: 100%;
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .modal_header {
      position: relative;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 2rem;
      }
    }

    .modal_body {
      .search {
        margin-bottom: 1rem;
      }

      .menu_items {
        .menu_list_of_items {
          height: 200px;
          overflow-y: auto;
        }

        .menu_item,
        .menu_select_all {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

          .menu_item_name {
            font-size: 14px;
            font-weight: 600;
            color: $color_black_1;
            margin-left: 4px;
          }
        }

        .menu_select_all {
          margin-bottom: 30px;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
    }
  }
}
