.section {
  .label {
    font-size: 14px;
    line-height: 1.5rem;
    text-transform: capitalize;
  }

  .field {
    width: 100%;
    margin-bottom: 1rem;
  }

  .additional_info {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .section {
    .additional_info {
      flex-wrap: wrap;
    }
  }
}
