@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color_white_3;

  .head {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $color_white_3;
    padding: 0.5rem 0;
    box-shadow: 0 4px 2px -2px #e7e7e7;
    z-index: 999;
  }

  .logo {
    width: 3rem;
    user-select: none;
  }

  .content {
    padding: 1rem;
    margin-top: 4rem;
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 2.5rem;
      margin: 2rem 0;
    }
  }

  .terms_info {
    margin-bottom: 1rem;
    text-align: center;

    h4 {
      font-size: 1.2rem;
      margin: 0.5rem 0;
    }
  }

  .form {
    width: 100%;

    .formGroup {
      margin-bottom: 1.5rem;

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 40rem #fffabc inset;
      }

      input:focus {
        outline: none;
        border-color: $color_accent;
      }
    }

    .i_agree {
      margin-bottom: 1rem;

      .error {
        color: #e90000;
        font-size: 0.8rem;
        padding: 4px 0;
      }
    }

    .checkbox {
      display: inline-flex;

      p {
        margin: 0.5rem 0;
      }

      a {
        color: $color_accent;
        text-decoration: none;
        font-weight: bold;

        &:visited {
          color: $color_accent;
          text-decoration: none;
        }
      }
    }

    .submit_button {
      margin-bottom: 1rem;
    }

    .has_account {
      a {
        color: $color_accent;
        text-decoration: none;
        font-weight: bold;

        &:visited {
          color: $color_accent;
          text-decoration: none;
        }
      }
    }
  }
}
