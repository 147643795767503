.contentWrapper {
  padding: 20px 30px;
}

.tableTopBorder {
  border-bottom: #e6e8e9 1px solid;
  margin-bottom: 15px;
}

.borderBox {
  border: #d6e2ee 1px solid;
}

.boxBody {
  background: #fff;
  padding: 20px;
  border-radius: 0;
  overflow: auto;
}

.list_controller {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  .filter_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.selector {
  min-width: 190px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
