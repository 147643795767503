@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .reporting_time {
      max-width: 600px;
      padding-bottom: 30px;

      .selector_wrapper {
        .date_picker {
          width: 100%;
        }
      }
    }

    .subtitle {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: $title_color;
      border-bottom: 1px solid $border_color;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      width: 100%;
    }

    .reporting_email {
      .add_email_wrapper {
        display: flex;
        gap: 0.75rem;
        margin-bottom: 1rem;

        .input_email {
          max-width: 600px;
        }

        .add_button {
          min-width: 170px;
          margin-top: 24px;
        }
      }

      .email_list {
        margin-bottom: 1rem;
      }

      .save_button {
        max-width: 170px;
      }
    }
  }
}

@media (max-width: 768px) {
  .add_email_wrapper {
    flex-direction: column;

    .add_button {
      margin-top: 10px;
      width: 100%;
    }
  }
}
