@import '../../../../../assets/styles/colors';

.modifier_cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5rem;

  .modifier_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    width: 325px;
    height: 68px;
    border: 1px solid $border_color;
    background-color: $color_white_3;
    border-radius: 6px;

    .modifiers_group_name {
      font-weight: 600;
      cursor: pointer;
    }

    .items_counter {
      color: $text_disabled_color;
      line-height: 20px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .name_and_button {
      display: flex;
      justify-content: space-between;
    }

    .ellipsis_button {
      position: relative;
      display: flex;
      width: 24px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .ellipsis_icon {
        position: relative;
        padding: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        background-color: $color_neutral_600;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        transform: rotate(90deg);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 6px;
          width: 4px;
          height: 4px;
          background-color: $color_neutral_600;
          border-radius: 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 6px;
          width: 4px;
          height: 4px;
          background-color: $color_neutral_600;
          border-radius: 50%;
        }
      }

      &:hover {
        .ellipsis_icon {
          background-color: $color_neutral_800;

          &::before,
          &::after {
            background-color: $color_neutral_800;
          }
        }
      }

      &:active {
        .ellipsis_icon {
          width: 5px;
          height: 5px;

          &::before {
            right: 7px;
            width: 5px;
            height: 5px;
          }

          &::after {
            left: 7px;
            width: 5px;
            height: 5px;
          }
        }
      }

      .dropdown {
        position: absolute;
        top: 0px;
        left: 26px;
        right: auto;
        min-width: 100px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        list-style: none;
        padding: 8px 0;
        margin: 0;
        z-index: 100;

        &.out_of_edge {
          top: 20px;
          left: auto;
          right: 0;
        }

        li {
          padding: 4px 16px;
          cursor: pointer;
          transition: background-color 0.2s;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #334155;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
}
