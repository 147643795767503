@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.employees_with_role {
  text-align: left;
  width: 25%;

  &_head {
    text-align: left;
  }
}

.full_name {
  color: #007bff;
  font-weight: 600;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.assign_button {
  color: $accent_color;
  font-weight: 700;
  cursor: pointer;
  text-wrap: nowrap;

  a {
    text-decoration: none;
    color: $accent_color;
  }

  a:visited {
    color: $accent_color;
  }
}

.row_actions_button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:first-child {
    margin-right: 6px;
  }

  svg {
    color: $accent_color;
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 768px) {
  .full_name {
    color: #fff;
    text-decoration: underline;
  }

  .employees_with_role {
    width: 100%;
  }
}
