@import '../../../assets/styles/colors';

.wrapper {
  .label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px !important;

    .starlet {
      color: $accent_color !important;
    }
  }
  .description {
    width: 100%;
    min-height: 100px;
    border: $border_color 1px solid;
    outline: none;
    resize: none;
  }

  .description::placeholder {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #94A3B8;
    padding: 6px;
  }
}
