@import '../../../../../assets/styles/colors';

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  .modifier_group_fields {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .field {
      flex-basis: 0;
    }

    .name {
      flex-grow: 7;
    }

    .min_max {
      flex-grow: 1;
    }
  }

  .form_container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-right: 30px;
    margin-bottom: 1rem;

    .field {
      flex-basis: 0;
    }

    .name {
      flex-grow: 6;
    }

    .min_max {
      flex-grow: 1;
    }

    .price {
      flex-grow: 2;
    }
  }

  .delete_button {
    position: absolute;
    top: 46px;
    right: 0;
    padding: 0 !important;

    &.sub_modifier {
      top: 34px;
    }
  }

  .dynamic_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 65vh;
    padding-right: 6px;
    // border: 1px solid $border_color;
    border-radius: 6px;

    &.sub_modifier {
      overflow: visible;
      max-height: 100%;
      border: none;
    }
  }

  .dynamic_row_container {
    position: relative;
    border: 1px solid $border_color;
    border-radius: 6px;
    background-color: $color_neutral_50;
    padding: 16px 16px 4px;

    &.sub_modifier {
      border: 1px solid $color_neutral_200;
      background-color: $color_neutral_100;
      padding: 6px 16px;
    }
  }

  .add_dynamic_field_button {
    width: 30%;
    padding: 8px 0;

    &.sub_modifier {
      width: 35%;
      margin-top: 0.5rem;
      padding: 0;
    }
  }
}

.sub_modifier_form {
  padding: 16px;
}

.modifier_form {
}
