@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .add_discount {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;

      .section {
        max-width: 520px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 300px;

        .discount_name_input,
        .discount_percent_input {
          min-width: 300px;
          margin-bottom: 0 !important;
        }
      }

      .add_button {
        margin-top: 24px;
      }

      .discount_rate_wrapper {
        position: relative;

        .discount_type_buttons {
          display: flex;
          position: absolute;
          gap: 0.2rem;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);

          .discount_type_button {
            border-radius: 6px;
            border: 1px solid $accent_color;
            background-color: $background_color;
            padding: 5px 5px;
            cursor: pointer;
            font-size: 14px;
            transition: border 0.3s;
            width: 32px;
            height: 32px;

            &.active {
              background-color: $accent_color;
              color: $color_white_3;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main_container {
    .list_container {
      .add_discount {
        margin-bottom: 2rem;

        .add_button,
        .section {
          width: 100%;
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }
}
