@import '../../../../../assets/styles/colors';

.modal {
  max-width: 450px;

  .category_edit_container {
    min-height: 100%;
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .head {
      margin-bottom: 2rem;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      .description {
        color: $text_placeholder_color;
        font-weight: 400;
      }
    }

    .form_container {
      position: relative;

      .fields {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .field {
          position: relative;
          width: 100%;
          margin-bottom: 1rem;
        }

        .selector_field {
          position: relative;
        }
      }

      .action_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.75rem;
      }
    }
  }

  .employee_info_container {
    padding: 1.5rem;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .form_section {
      margin-bottom: 1rem;

      .primary_info {
        margin-bottom: 2rem;

        p {
          color: $color_accent_500;
          margin: 0;
        }
      }
      .additional_info {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        .info_row {
          display: flex;
          justify-content: space-between;

          .info_value {
            font-weight: 500;
            color: $color_accent_500;
          }
        }
      }
    }

    .action_buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.75rem;

      .delete_btn {
        min-width: 72px;
        margin-right: auto;
      }

      .close_btn {
        min-width: 72px;
      }
    }
  }
}
