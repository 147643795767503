@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .main_content {
    position: relative;
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;

    .menu_item {
      display: flex;
      margin-bottom: 30px;

      .menu_item_name {
        cursor: pointer;
      }
    }

    .save_button {
      width: 170px;
      margin-top: auto;
    }
  }
}
