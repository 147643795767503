@import '../../../assets/styles/colors';

$switch-width: 28px; // switcher width
$switch-height: 16px; // switcher height
$switch-slider-size: 12px; // circle size
$switch-translate: calc($switch-width / 2); // circle shift value

$accent_switch_color: $color_additional_green_500;
$circle_slider_color: $color_white_3;
$background_unchecked_color: $color_neutral_300;

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $accent_switch_color;

      &::before {
        transform: translateX($switch-translate);
        background-color: $color_white_3;
        bottom: calc(($switch-height - $switch-slider-size) / 2);
        left: 0 !important;
      }
    }
  }

  .slider.round {
    border-radius: 50px;

    &:before {
      border-radius: 50%;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background_unchecked_color;
    transition: 0.4s;
    border-radius: calc($switch-height / 2);

    &:before {
      position: absolute;
      content: '';
      height: $switch-slider-size;
      width: $switch-slider-size;
      left: calc(($switch-height - $switch-slider-size) / 2) !important;
      bottom: calc(($switch-height - $switch-slider-size) / 2);
      background-color: $circle_slider_color;
      transition: 0.4s;
    }
  }
}
