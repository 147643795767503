@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.wrapper {
  width: 100%;
  height: 100vh;
  background-color: $color_white_3;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: 530px;
  border: 1px solid $color_gray_2;

  .logo {
    padding: 0 4rem;
    margin: 3rem 0;
    user-select: none;
  }

  .title {
    color: $color_black_1;
    display: block;
    margin-bottom: 20px;
  }

  .form {
    margin-bottom: 20px;
    width: 100%;
  }

  .submit_button {
    font-size: 1rem;
    color: $color_white_1;
  }
}

.formGroup {
  margin-bottom: 20px;

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    color: $color_black_1;

    border: 1px solid $color_gray_3;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 40rem #fffabc inset;
  }

  input:focus {
    outline: none;
    border-color: $color_accent;
  }
}

.back {
  width: 100%;

  span {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  a {
    font-weight: bold;
    vertical-align: middle;
    color: $color_accent;
    text-decoration: none;

    &:visited {
      color: $color_accent;
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .login {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .login {
    width: calc(100% - 20px);
  }
}
