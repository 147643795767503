@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .title_container {
        display: flex;
        gap: 0.5rem;
        align-items: flex-end;

        .title {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .list_controller {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.75rem;

      .role_selector {
        min-width: 190px;
      }

      .search {
        min-width: 280px;
      }
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .sub_title {
      border-bottom: 1px solid $border_color;
      padding-bottom: 4px;
    }
  }
}

.item {
  padding: 20px;
}

.role_selector {
  min-width: 190px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.search {
  min-width: 280px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.new_category_name {
  min-width: 190px;
  width: 300px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.buttons {
  display: flex;
  flex-wrap: wrap;

  .add_button {
    margin-right: 10px;
    & button {
      background: $color_accent;

      &:hover {
        background: $color_accent_hover;
      }
    }
  }

  .delete_button {
    & button {
      color: $color_accent;
      background: $color_gray_1;
      border: none;

      &:hover {
        background: $color_gray_4;
      }
    }
  }
}

.list_controller {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .filter_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
}

.menu_items {
  .items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 192px;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .loading {
      // border: 1px solid $border_color;
      // border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 192px;
    }
  }
}

.modifier_groups {
  .items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 192px;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .loading {
      // border: 1px solid $border_color;
      // border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 192px;
    }
  }
}

.card_container {
  width: 180px;
  height: 192px;
  display: flex;
  flex-direction: column;
  border: 1px solid $border_color;
  border-radius: 6px;
  background-color: $color_white_3;

  .head {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border_color;
    gap: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    .cost_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $text_placeholder_color;
        font-weight: 400;
      }

      .tag {
        $text-color: var(--text-color);
        padding: 0 4px;
        min-width: 40px;
        height: 20px;
        border-radius: 14px;
        text-align: center;
        color: rgb($text-color);
        background-color: rgba($text-color, 0.15);
      }
    }
    .onpos_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $text_placeholder_color;
        font-weight: 400;
      }
    }
  }
  .image {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: $color_neutral_100;
    height: 100%;
    border-radius: 0 0 5px 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .no_items {
      svg {
        width: 24px;
        height: 24px;
        margin-bottom: 0.5rem;

        path {
          stroke: $color_neutral_300;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
        text-transform: unset;
      }
    }
  }
}

.no_data {
  position: relative;
  height: 192px;
  width: 100%;
  border: 1px solid $border_color;
  border-radius: 6px;

  .no_items {
    svg {
      width: 60px;
      height: 60px;
      margin-bottom: 1rem;

      path {
        stroke: $color_neutral_300;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.skeleton_box {
  @include skeleton_box();
}

@media (max-width: 768px) {
  .role_selector {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .new_category_name {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .employee_edit_section {
    margin-left: 0;

    .content {
      padding: 0;

      .form_container {
        .fields {
          flex-direction: column;

          .field {
            max-width: 100%;
          }
        }
      }
    }
  }
}
