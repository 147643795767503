// Mixin for button styles
@mixin button-style($width) {
  width: $width;
  background: $color_accent;
  color: $color_gray_1;
  padding: 14px;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

@mixin skeleton_box() {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background-color: $color_neutral_200 !important;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
