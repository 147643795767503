@import '../../../../assets/styles/colors';

.item_name {
  text-decoration: none;
  color: $color_blue_1;
  font-weight: 600;
  cursor: pointer;
}

.image_wrapper {
  width: 100%;
  height: 100%;
  min-width: 40px;
  min-height: 40px;
  position: relative;

  overflow: hidden;

  & img {
    width: 70px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
