.date_picker {
  margin: 0 !important;
  width: 100% !important;
}

.status_selector {
  width: 40%;
}

.cell {
  word-break: normal !important;
}
