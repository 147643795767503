@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.head {
  text-align: center !important;
}

@media (max-width: 768px) {
  .settings_row {
    div {
      &:first-child {
        &:has(input) {
          color: inherit;
          background-color: $color_white_3;
          border-color: inherit;
        }

        &::before {
          color: $color_white_3;
          border: none;
        }
      }
    }
  }
}
