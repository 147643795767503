@import './colors';
@import './variables';

.table-top-border {
  border-bottom: #e6e8e9 1px solid;
  margin-bottom: 15px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;

  .thead-dark {
    th {
      color: $color_black_1;
      background-color: #dee2e6;
      border-color: #dee2e6;
    }
  }

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  tbody + tbody {
    border-top: 2px solid #dee2e6;
  }

  .table-bordered {
    border: 1px solid #dee2e6;

    th,
    td {
      border: 1px solid #dee2e6;
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
}
