@import '../../../assets/styles/colors';

.tooltipContainer {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  z-index: 9999;
}

.tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 14px;
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  min-height: 30px;
  opacity: 1;
  transition: opacity 3s ease-in-out;
}

.tooltip-succeeded {
  background-color: rgba(4, 167, 93, 0.9);
}

.tooltip-failed {
  background-color: rgba(224, 93, 77, 0.9);
}

.tooltip-warning {
  background-color: rgba(255, 165, 0, 0.9);
}

.closeBtn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.tooltip.fade-out {
  opacity: 0;
}
