.modal_content {
  padding: 1rem;
}

.add_title_modal {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0;
}

.date_picker {
  margin-right: 0 !important;
  width: 100% !important;
}

.row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.field {
  width: 100%;
}

.add_button {
  width: 170px;
}
