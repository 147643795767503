@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $color_neutral_300;
    background-color: $color_white_3;

    .title {
      margin-bottom: 1rem;
    }
  }

  .list_container {
    position: relative;
    flex: 1;
    padding: 15px;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;

  .filter_wrapper {
    .selector {
      width: 200px !important;
    }
  }
}

.employee_name {
  text-decoration: none;
  color: $color_blue_1;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 1120px) {
  .title {
    margin-bottom: 0px;
  }

  .filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .main_container {
    .head_container {
      padding: 0;
    }
  }

  .filters {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;

    .filter_wrapper {
      width: 100%;

      .selector {
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
  }
}
