@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_and_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list_container,
  .form_container {
    position: relative;
    flex: 1;
    padding: 15px;

    .fields {
      position: relative;
      display: flex;
      flex-wrap: wrap;

      .row {
        display: flex;
        gap: 1rem;
        width: 100%;
        margin-bottom: 1rem;
      }

      .field {
        position: relative;
        width: 100%;
        max-width: 50%;
      }

      .switcher {
        width: auto;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
          font-size: 12px;
          color: #1d1d1f;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
}

@media (max-width: 768px) {
  .main_container {
    margin-left: 0;
    .form_container {
      .fields {
        .row {
          flex-direction: column;

          .field {
            max-width: 100%;
          }
        }
      }
    }
  }
}
