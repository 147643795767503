@import 'colors';

.main-title {
  font-size: 48px;
  color: $text_color;
  font-weight: bold;
  line-height: 52px;
  @media (max-width: 1199px) {
    font-size: 44px;
    line-height: 48px;
  }
  @media (max-width: 991px) {
    font-size: 38px;
    line-height: 44px;
  }
  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 38px;
  }
  @media (max-width: 575px) {
    font-size: 28px;
    line-height: 32px;
  }
  @media (max-width: 360px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.admin-main-title {
  font-size: 28px;
  font-weight: 400;
}

p {
  font-size: 19px;
  color: $text_color;
  line-height: 24px;
  &.sub-title {
    font-size: 19px;
    color: $text_color;
    line-height: 24px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &.sub-title-xl {
    font-size: 34px !important;
    line-height: 34px;
    @media (max-width: 1199px) {
      font-size: 28px !important;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 24px !important;
      line-height: 24px;
    }
    @media (max-width: 575px) {
      font-size: 16px !important;
      line-height: 22px;
    }
  }
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}
.pl-6,
.px-6 {
  padding-left: 4rem !important;
}
.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}
.pl-7,
.px-7 {
  padding-left: 5rem !important;
}
.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.mt-2-5,
.my-2-5 {
  margin-top: 2.188rem !important;
}
.mb-2-5,
.my-2-5 {
  margin-bottom: 2.188rem !important;
}
.ml-2-5,
.mx-2-5 {
  margin-left: 2.188rem !important;
}
.mr-2-5,
.mx-2-5 {
  margin-right: 2.188rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.slider-container {
  max-width: 1340px !important;
}

.carousel {
  margin: 0 auto;
  padding: 0 70px;
  @media (max-width: 767px) {
    padding: 0 40px;
  }
  .carousel-inner {
    border-bottom: rgba(134, 134, 139, 0.1) 1px solid;
    padding-bottom: 50px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
  .item {
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .carousel-control {
    background-color: #f2f2f2;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 1;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
    i {
      font-size: 36px;
      color: rgba(162, 162, 162, 1);
      display: inline-block;
      position: absolute;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    &:hover {
      background-color: #efecec;
      i {
        color: rgba(142, 142, 142, 1);
      }
    }
  }
}

.w-auto {
  width: auto !important;
}
.min-w-auto {
  min-width: auto !important;
}
.min-h-auto {
  min-height: auto !important;
}
.relative {
  position: relative !important;
}
.italic {
  font-style: italic;
}
.h-70 {
  height: 70px !important;
}
.text-underline {
  text-decoration: underline;
}
.box-shadow-0 {
  box-shadow: none !important;
}

.border-rd {
  &-70 {
    border-radius: 70px !important;
  }
  &-50 {
    border-radius: 50px !important;
  }
  &-30 {
    border-radius: 30px !important;
  }
}

.rounded {
  &-5 {
    border-radius: 5px !important;
  }
  &-10 {
    border-radius: 10px !important;
  }
  &-25 {
    border-radius: 25px !important;
  }
}
