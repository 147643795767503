@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

// Variables
$transition-duration: 0.3s;
$menu-padding: 16px;
$menu-item-padding: 15px 20px;
$border-radius: 6px;
$icon-margin: 15px;
$collapsed-width: 90px;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin menu-link-base {
  @include flex-center;
  color: $text_color;
  font-weight: 600;
  justify-content: flex-start;
  text-decoration: none !important; // !imported should be removed after bootstrap
  border-radius: $border-radius;
  padding: $menu-item-padding;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color_accent_500;
    border-radius: 4px;

    &:hover {
      background: $color_accent_600;
    }

    &:active {
      background: $color_accent_700;
    }
  }
}

// Main Container
.sidebar_container {
  min-width: 0;
  flex: 1 1 20%;
  height: 100%;
  color: $color_black_1 !important;
  background-color: $color_white_3 !important;
  border-right: 1px solid $color_gray_1;
  border-bottom: 1px solid $color_gray_1;
  transition:
    flex $transition-duration,
    opacity $transition-duration;

  &.collapsed {
    flex: 0 0 $collapsed-width;

    &.hidden {
      flex: 0 0 0;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Sidebar
.sidebar {
  height: 100%;
  width: auto;
  padding: 8px 0;
  z-index: 50;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow: {
    y: auto;
    x: hidden;
  }

  @include scrollbar;

  nav {
    padding: 0 $menu-padding;
  }
}

// Menu Items
.menu_item {
  margin: 8px 0;

  &_link {
    @include menu-link-base;
    color: $text_color;
    background-color: transparent;

    &:not(:has(div.menu_item_icon)) {
      padding-left: 20px;
    }

    &:hover {
      color: $text_color;
      background-color: $color_neutral_100;
    }
  }

  &_icon {
    margin-right: $icon-margin;

    svg path {
      stroke: $color_accent_800;
    }
  }

  &_name {
    display: block;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition:
      opacity 300ms ease-out,
      display 400ms allow-discrete,
      overlay 400ms allow-discrete;

    &.collapsed {
      display: none;
      opacity: 0;

      @starting-style {
        display: block;
        opacity: 1;
      }
    }
  }

  &_arrow {
    display: block;
    margin-left: auto;

    &.collapsed {
      display: none;
    }
  }
}

// Active States
.menu_item {
  &.active {
    .menu_item_link {
      color: $color_white_3;
      background-color: $color_accent_800;

      &:hover {
        background-color: $color_neutral_600;
      }

      .menu_item_icon svg path {
        stroke: $color_white_3;
      }
    }
  }

  .sub_item_section {
    .menu_item {
      padding-left: 28px;
    }
  }

  &.parent {
    &.active {
      border-radius: 6px;
      background-color: $color_neutral_100;

      .menu_item_link:hover {
        background-color: $color_accent_600;
      }

      .sub_item_section {
        .label {
          display: block;
          margin: 16px 0;
          padding-left: 22px;
          font-weight: 600;
          text-decoration: underline;
        }

        .menu_item_link {
          color: $text_color;
          background-color: $color_neutral_100;

          &.active {
            color: $color_white_3;
            background-color: $color_accent_800;

            &:hover {
              background-color: $color_accent_500;
            }
          }

          &:hover {
            background-color: $color_accent_200;
          }
        }
      }
    }

    .menu_item_arrow svg {
      transform: rotate(180deg);
      path {
        stroke: $color_accent_800;
      }
    }

    a.active .menu_item_arrow svg {
      transform: rotate(0deg);
      path {
        stroke: $color_white_3;
      }
    }
  }
}

// Logout Section
.logout {
  padding: 0 $menu-padding;
  margin-top: auto;

  a {
    @include menu-link-base;
    font-size: 14px;
    color: $color_black_1;
    background-color: transparent;

    &:hover {
      background-color: $color_neutral_100;
    }

    .menu_item_icon {
      margin-right: $icon-margin;
      svg path {
        stroke: $color_accent_800;
      }
    }

    .menu_item_name {
      opacity: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition:
        flex 1s,
        opacity 1s;

      &.collapsed {
        opacity: 0;
      }
    }
  }
}

// Media Queries
@media (max-width: 1100px) {
  .sidebar_container {
    flex: 1 1 28%;

    &.collapsed {
      flex: 0 0 $collapsed-width;
    }
  }
}

@media (max-width: 768px) {
  .sidebar_container {
    flex: 1 1 100%;

    &.collapsed {
      flex: 0 0 $collapsed-width;
    }
  }

  .sidebar {
    overflow: hidden;
    width: 100%;
    top: 90px;
  }
}
