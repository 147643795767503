@import '../../../assets/styles/colors';

.terms {
  color: $color_black_2;
  line-height: 1.6;

  @media (max-width: 750px) {
    padding: 15px;
  }
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: $color_black_1;
}

.section {
  margin-bottom: 30px;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: $color_black_3;
}

.list {
  padding-left: 20px;
  list-style-type: disc;

  li {
    margin-bottom: 10px;
  }
}

.link {
  color: $color_accent;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
