// Define the number of columns
$grid-columns: 12;

// Mixin to generate column classes
@mixin make-col($size) {
  width: percentage($size / $grid-columns);
}

// Row class
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

// Column classes
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    padding-right: 15px;
    padding-left: 15px;
    @include make-col($i);
  }
}
