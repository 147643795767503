@import '../../../../assets/styles/colors';

.full_name {
  color: $color_additional_link;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 768px) {
  .full_name {
    color: $color_white_3;
    text-decoration: underline;
  }
}
