@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .main_content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;

    .menu_item {
      display: flex;
      margin-bottom: 30px;
      user-select: none;

      .menu_item_name {
        cursor: pointer;
      }
    }

    .save_button {
      width: 170px;
      margin-top: auto;
    }
  }
}

@media (max-width: 768px) {
  .main_container {
    .main_content {
      .save_button {
        width: 100%;
      }
    }
  }
}
