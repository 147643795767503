@import '../../../assets/styles/colors';

$input-height: 40px;

.container {
  position: relative;
  width: 100%;

  .label {
    display: inline-block;
    color: $label_color;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.4s ease;
  }

  &:has(input:disabled) {
    .label {
      color: $text_disabled_color;
    }
  }

  .asterisk::after {
    color: $red_error_color;
    content: '*';
  }

  .selector {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $input-height;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $text_color;
    padding: 4px 12px 4px 12px;
    border: 1px solid $border_color;
    border-radius: 6px;
    background-color: $background_color;
    background-image: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    outline: none;

    &:active,
    &:focus {
      border: 2px solid $border_focus_color;
      outline: none;

      &[disabled] {
        border-width: 1px;
        border-color: $border_color;
      }
    }

    .field {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .placeholder {
        color: $text_placeholder_color;
      }
    }

    svg {
      transition: transform 0.2s ease;
      stroke: $text_color;

      &.chevron {
        transform: rotate(0deg);
        fill: $color_accent_800;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    &[disabled] {
      .field {
        .value {
          color: $text_disabled_color;
        }
      }

      svg {
        &.chevron {
          fill: $text_disabled_color;
        }
      }
    }
  }

  .dropdown_panel {
    position: absolute;
    width: 100%;
    border: 1px solid $border_color;
    border-radius: 6px;
    z-index: 999;
    margin-top: 1px;
    background-color: $background_color;
    box-shadow: 0px 4px 6px 0px #00000017;

    .action_buttons {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
      padding: 0.5rem;
      border-bottom: 1px solid $border_color;

      button {
        padding: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $link_color;
        border: none;
        border-radius: 6px;
        background-color: transparent;
        gap: 0.75rem;

        &:hover {
          background-color: $main_neutral_background_color;
        }

        &.remove_all {
          color: $red_color;
        }
      }
    }

    .search {
      padding: 8px;
      .search_container {
        position: relative;

        svg.lens_icon {
          stroke: $icon_color;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }

        input {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          height: $input-height;
          padding: 10px 10px 10px 32px;
          width: 100%;
          color: $text_color;
          border: 1px solid $border_color;
          border-radius: 6px;
          background-color: $background_color;
          background-image: none;

          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $text_placeholder_color;
          }

          &:active,
          &:focus {
            border: 2px solid $border_focus_color;
            outline: none;
          }
        }
      }
    }

    .dropdown_list {
      padding: 0 8px 8px 8px;
      max-height: 25vh;
      overflow-y: auto;

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 8px;
        border-radius: 6px;

        &:hover {
          background-color: $background_hover_color;
        }

        input[type='checkbox'] {
          display: none;
          user-select: none;
        }

        .checkbox_label {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 0;

          & svg {
            width: 10px;
          }

          .custom_checkbox {
            width: 16px;
            height: 16px;
            border: 1px solid $border_color;
            border-radius: 2px;
            background-color: $background_color;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition:
              background-color 0.2s ease,
              border-color 0.2s ease;
            padding: 2px;

            .check_icon {
              color: $background_color;
              opacity: 0;

              &.checked {
                opacity: 1;
              }
            }

            &:has(.checked) {
              background-color: $accent_color;
            }
          }
        }

        .name {
          color: $text_color;
          font-size: 14px;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }

  .error_container {
    padding: 0;

    .error {
      color: $red_error_color;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 2px;
    }
  }

  &:has(.error_container) {
    .selector {
      border-color: $red_error_color;
    }
  }

  .tags_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 4px;

    .tag {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $title_color;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      background-color: $background_slider_color;
      border: 1px solid $border_color;
      border-radius: 36px;
      padding: 5px 8px 5px 6px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      .button {
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        justify-content: center;
        height: 16px;
        width: 16px;
        background-color: $color_neutral_300;
        border-radius: 50%;

        svg {
          fill: $color_neutral_700;
        }

        &:hover {
          background-color: $color_neutral_300;

          svg {
            fill: $color_neutral_500;
          }
        }
      }
    }
  }

  .spinner {
    position: absolute;
    width: 24px;
    height: 24px;
    border-width: 4px;
    top: calc(50% - 12px);
    right: 8px;
    left: auto;
  }
}
