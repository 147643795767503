@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background_color;
  z-index: 1000;
}

.modal_content {
  padding: 1.2rem;
  background-color: $background_color;
  box-sizing: border-box;
  //   width: 50vw;

  .edit_title_modal {
    color: $text_color;
    font-size: 20px;
    font-weight: 600;
    padding: $padding-default 0;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .role_name_wrapper {
    margin-bottom: 1rem;
  }

  .select_item {
    color: $text_color;
    width: 100%;
    margin-bottom: 1rem;

    label {
      margin-bottom: 0.5rem;
      display: block;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .item_list {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        padding: 4px;
        border: 1px solid $border_color;
        border-radius: 4px;
        height: 200px;
        overflow-y: scroll;
        width: 100%;

        .item {
          background: $background_light_color;
          color: $text_color_light;
          border-radius: 4px;
          cursor: pointer;
          padding: 10px;

          &_selected {
            color: $invert_text_color !important;
            background: $accent_color !important;
          }
        }
      }
    }
  }
}
