.contentWrapper {
  padding: 20px 30px;
}

.tableTopBorder {
  border-bottom: #e6e8e9 1px solid;
  margin-bottom: 15px;
}

.borderBox {
  border: #d6e2ee 1px solid;
}

.boxBody {
  background: #fff;
  padding: 20px;
  border-radius: 0;
}
