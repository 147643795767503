@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/fonts';

.add_or_edit_item_page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 96px);
  box-sizing: border-box;

  .header {
    width: 100%;
    padding: 20px;
    background: $color_white_3;
    border-bottom: 1px solid $color_accent_300;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px;
    padding: 20px;
    background: $color_white_3;
    border: 1px solid $color_accent_300;
    border-radius: 8px;
    height: 100%;
    flex-grow: 1;

    .subtitle {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $color_accent_500;
    }

    .settings {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .inputs_form {
        flex: 1;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-right: 20px;

        .field {
          position: relative;
          padding-right: 15px;
          flex: 0 0 50%;
          margin-top: 10px;

          .name_input {
            & input::placeholder {
              color: $color_accent_400;
            }
          }

          .price_input {
            max-width: 120px;

            & input::placeholder {
              color: $color_accent_400;
            }
          }

          .description {
            & label {
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: $color_neutral_900;
            }

            & textarea {
              border-radius: 6px;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: $color_accent_400;
              padding: 6px 12px;
            }
          }
        }

        .full_width {
          flex: 0 0 100%;
        }
      }

      .item_image {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        flex: 1;

        .requirements {
          display: block;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $color_accent_500;
          margin-bottom: 4px;
        }

        .image_content {
          display: flex;
          width: 100%;
          height: 100%;
          max-width: 340px;
          max-height: 200px;
          border: 1px solid $color_neutral_300;
          border-radius: 6px;
          overflow: hidden;

          .image_wrapper {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: $color_accent_100;
            cursor: pointer;

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .no_image_stub {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              text-align: center;
              font-size: 14px;
              cursor: pointer;

              .no_image_icon {
                font-size: 24px;
                color: $color_gray_2;
              }

              .stub_description {
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: $color_accent_400;
                padding: 5px;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: auto;
      display: flex;
      justify-content: flex-end;

      .button:first-child {
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .settings {
    flex-direction: column;
  }

  .item_image {
    margin-top: 40px;

    .image_content {
      min-width: 340px;
      min-height: 200px;
    }
  }
}

@media (max-width: 768px) {

}
