@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.metric {
  flex: 1 1 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $color_gray_2;
  margin: 5px;
  padding: 10px;
  position: relative;
  text-align: center;

  .icon {
    margin-bottom: 12px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .value {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 18px;
  }

  .title_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: $color_black_3;
    font-size: 14px;

    .title {
      font-size: 14px;
      font-weight: 400;
      color: $color_black_1;
    }

    .info_icon {
      font-size: 14px;
      width: 18px;
      height: 18px;
      color: $color_black_1;
      border-radius: 50%;
      margin-left: 8px;
      position: relative;
      top: -2px;
      cursor: pointer;
    }

    .info_tooltip {
      position: absolute;
      max-width: 200px;
      min-width: 200px;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-black_2;
      color: white;
      padding: 8px 8px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 18px;
      z-index: 10;
      opacity: 0.9;
      box-shadow: 0px 4px 8px $color-gray_2;
    }
  }
}
