@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

:global {
  .react-datepicker-wrapper {
    height: 100%;

    .react-datepicker__input-container {
      input {
        font-size: 14px;
        padding: 4px 46px 4px 12px;
        color: $text_color;
        width: 100% !important;
        height: 100% !important;
        border: 1px solid $border_color;
        border-radius: 6px;

        &:focus {
          background-color: transparent;
          border-width: 2px;
          border-color: $border_focus_color;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .react-datepicker {
    display: inline-flex !important;
    border: 1px solid $border_color !important;

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      display: none;
    }

    .react-datepicker__header {
      background-color: $main_neutral_background_color;
      border-bottom: 1px solid $border_color;

      h2.react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
        display: flex;
        gap: 0.5rem;
        margin: 6px 14px;
      }

      .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
        width: 100%;
        margin: 0;

        select.react-datepicker__month-select {
          height: 26px;
          width: 100%;
          color: $text_color;
          border: $border_color 1px solid;
          border-radius: 4px;
        }

        select[attr='size'] {
          width: auto;
          height: auto;
        }
      }

      .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
        width: 100%;
        margin: 0;

        select.react-datepicker__year-select {
          height: 26px;
          width: 100%;
          color: $text_color;
          border: $border_color 1px solid;
          border-radius: 4px;
        }
      }
    }

    .react-datepicker__time {
      .react-datepicker__time-list {
        .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
          background-color: $accent_color !important;
          color: $invert_text_color !important;
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day.react-datepicker__day--selected {
          background-color: $accent_color;

          &.react-datepicker__day--today {
            color: $invert_text_color;
          }
        }

        .react-datepicker__day--today {
          color: $accent_color;
        }

        .react-datepicker__day--keyboard-selected {
          border: 1px solid $accent_color;
          background-color: transparent;
        }
      }
    }
  }
}

.container {
  .label {
    display: inline-block;
    color: $label_color;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.4s ease;
  }

  .input_container {
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .prefixIcon {
      display: inline-flex;
      color: $text_color;
    }

    .calendar_icon {
      position: absolute;
      top: 0px;
      right: 0;
      border-left: 1px solid $border_color;
      height: 100%;
      padding: 9px;
      cursor: pointer;
      z-index: 99;

      svg {
        path {
          stroke: $accent_color;
        }
      }
    }

    .date_picker {
      height: 100%;
      width: 100%;
      display: block;
    }

    &:has(input:focus) {
      .calendar_icon {
        border-color: $border_focus_color;
      }
    }
  }

  .asterisk::after {
    color: $red_error_color;
    content: '*';
  }

  .error_container {
    padding: 4px 0;

    .error {
      color: $red_error_color;
      font-size: 0.8rem;
      padding: 4px 0;
    }
  }

  &:has(.error_container) {
    .date_picker {
      input {
        border-color: $red_error_color;
      }
    }
  }
}
