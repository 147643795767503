@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 26px 10px 36px;
  width: 100%;
  color: $text_color;
  background-color: $main_background_color;
  border-bottom: 1px solid $main_border_color;
  flex-shrink: 0;
  z-index: 999;

  & .locationInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & .burgerWrapper {
      width: 20px;
      height: 20px;
      position: relative;
      background: none;
      border: none;
      cursor: pointer;
      color: $accent_color;

      & svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & .locationName {
      font-size: 14px;
      line-height: 20px;
      color: $text_color;
      font-weight: 500;
      margin: 0.4rem 0 0.4rem 0.8rem;
      white-space: nowrap;
    }
  }

  & .userInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & .userWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      & .userIcon {
        position: relative;
        color: $text_color;
        background: none;
        width: 16px;
        height: 16px;
        margin-right: 14px;

        & svg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          color: $text_color;
          background: none;
        }
      }

      & .userName {
        color: $text_color;
        font-family: 'SFProDisplayMedium', sans-serif;
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding: 10px;
  }
}
