@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  //justify-content: space-between;
  justify-content: flex-start;

  .subtitle {
    font-size: 16px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .content {
    position: relative;
    max-height: 500px;
    height: auto;
    overflow-y: auto;
    padding: 0 10px;

    .employee {
      border-top: 1px solid $color-gray_2;
      border-bottom: 1px solid $color-gray_2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px 0;
      margin-bottom: 10px;

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        .icon_wrapper {
          margin-right: 10px;
          position: relative;
          width: 10%;
          height: 100%;

          .icon {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -65%);
            vertical-align: baseline;
          }
        }

        .info_wrapper {
          text-align: left;
          width: 80%;

          .name {
            color: $color-black_1;
            font-weight: 600;
            font-size: 16px;
          }

          .time {
            color: $color-gray_3;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }

      .right {
        .hours {
          color: $color-black_1;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .no_data {
      color: $color-gray_3;
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
    }
  }
}
