@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

$input-height: 40px;
$placeholder-transition: all 0.3s ease;
$icon-size: 1.5rem;

$accent_color: $color_accent_800;
$background_color: $color_white_3;
$border_color: $color_neutral_300;
$border_focus_color: $color_neutral_400;
$border_disabled_color: $color_neutral_400;
$icon_color: $color_neutral_400;
$text_color: $color_neutral_900;
$invert_text_color: $color_white_3;
$text_placeholder_color: $color_neutral_400;
$text_disabled_color: $color_neutral_400;
$label_color: $color_neutral_900;
$red_error_color: $color_additional_red_500;

@mixin placeholder-active {
  top: 42.5%;
  font-weight: 600;
  color: $text_color;
  line-height: 14px;
  transform: translateY(-85%);
}

.wrapper {
  width: 100%;

  .selector {
    position: relative;
    width: 100%;

    .label {
      display: inline-block;
      color: $label_color;
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
    }

    .asterisk {
      position: relative;

      &::after {
        color: $red_error_color;
        content: '*';
      }
    }
  }
  .select_container {
    position: relative;

    .inner_placeholder {
      position: absolute;
      font-size: 14px;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: $placeholder-transition;
      pointer-events: none;
      color: $text_placeholder_color;
      margin: 0;

      &_active {
        @extend .inner_placeholder;
        @include placeholder-active;
      }
    }

    .select {
      width: 100%;
      height: $input-height;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      color: $text_color;
      padding: 4px 46px 4px 12px;
      border: 1px solid $border_color;
      border-radius: 6px;
      background-color: $background_color;
      background-image: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      outline: none;

      &::placeholder {
        color: $text_placeholder_color;
      }

      &:active,
      &:focus {
        border: 2px solid $border_focus_color;
        outline: none;
      }

      & option {
        font-size: 14px;

        border-radius: 4px;

        &:hover {
          background-color: $accent_color;
          color: $invert_text_color;
        }
      }

      &:disabled {
        border-color: $border_disabled_color;
      }
    }

    &:has(.select:-webkit-autofill) {
      .select {
        font-size: 14px;
        padding-top: calc($input-height / 2.3);
        height: $input-height;
      }

      .inner_placeholder {
        @include placeholder-active;
      }
    }

    &:has(.inner_placeholder_active) {
      .inner_placeholder_active {
        @include placeholder-active;
      }

      .select {
        height: $input-height;
        padding-top: calc($input-height / 2.3);
      }
    }

    &:has(.inner_placeholder) {
      .select {
        height: $input-height;
        padding-top: calc($input-height / 2.3);
      }
    }

    &:has(.with_prefix) {
      .inner_placeholder,
      .inner_placeholder_active {
        left: 3.05rem;
      }
    }

    &:has(.with_suffix) {
      .inner_placeholder,
      .inner_placeholder_active {
        right: 3.05rem;
      }
    }
  }

  .error_container {
    padding: 4px 0;

    .error {
      color: $red_error_color;
      font-size: 14px;
      padding: 4px 0;
    }
  }

  &:has(.error_container) {
    .select_container {
      .select {
        border-color: $red_error_color;
      }
    }
  }
}

.icon {
  width: 1em;
  height: 1em;
  top: calc(50% - 0.5em);
  color: $text_color;
  position: absolute;
  right: 8px;
  pointer-events: none;
}
