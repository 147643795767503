@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.items {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;

    .title_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .add_button {
        div {
          left: 17px;
        }
      }
    }

    .filters_container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.75rem;
      width: 100%;

      .category_selector {
        min-width: 190px;
        margin-bottom: 0.5rem;
      }

      .search {
        min-width: 280px;
        margin-bottom: 0.5rem;
      }
    }
  }

  .list_container {
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .role_selector {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .toggler {
    --toogler-width: 100%;

    span {
      width: 123px;
    }
  }

  .slider {
    width: 123px;
  }

  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
