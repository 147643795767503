@import '../../../../assets/styles/colors';

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .head_container {
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid $main_border_color;
    background-color: $color_white_3;
  }

  .content {
    position: relative;
    flex: 1;
    padding: 15px;

    .form_container {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      max-width: 500px;

      .date_picker {
        width: 100%;
      }
    }

    .save_button {
      width: 170px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .selector_container,
  .time_zone_container {
    max-width: 100%;
    width: 100%;
  }
}
