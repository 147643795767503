@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.date_range {
  .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border_color;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;

    .title {
      font-size: 1.1rem;
      margin: 0;
    }

    .date_settings {
      display: flex;
      align-items: center;
      width: max-content;
      gap: 0.5rem;
      cursor: pointer;

      .settings_icon {
        display: inline-flex;

        svg {
          color: $accent_color;
          font-size: 1.1rem;
        }
      }

      .text {
        color: $accent_color;
      }
    }
  }

  .presets {
    display: flex;
    gap: 0.5rem;
    list-style: none;
    margin-bottom: 1rem;
    padding: 0;

    .tab_button {
      font-size: 14px;
      background-color: $color_white_3;
      color: $accent_color;
      cursor: pointer;
      transition: all 0.1s;
      border-radius: 2px;
      padding: 2px 6px;

      &.active {
        background-color: $accent_color;
        color: white;
      }
    }
  }

  .date_pickers {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;

    .fields {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
