@import '../../../assets/styles/colors';

.container {
  .label {
    display: inline-block;
    color: #444;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  .nested_field {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;

    &:has(.table_row) {
      margin-bottom: 1rem;
    }

    .row {
      display: flex;
      align-items: center;

      .row_content {
        width: 100%;
      }

      .drag_cell {
        vertical-align: middle;
        padding: 0.5rem;

        .drag_icon {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          color: #525252;
          cursor: move;
        }

        &:first-child {
          text-align: center;
        }
      }

      &.table_row {
        border-top: 1px solid #e6e8e9;
        margin-bottom: 0;
      }

      &:has(label) {
        .action_area {
          padding-top: 1.5rem;
        }
      }
    }
  }

  .action_area {
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;

      &.disabled {
        background-color: $accent_color;
      }

      svg {
        color: $accent_color;
        width: 22px;
        height: 22px;
      }
    }
  }

  &.bottom_line {
    border-bottom: 1px solid #e6e8e9;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}

.row_actions_button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:first-child {
    margin-right: 6px;
  }

  svg {
    color: #ed5950;
    width: 22px;
    height: 22px;
  }
}
