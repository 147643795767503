.search {
  min-width: 280px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.list_controller {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  .filter_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.option_edit_section {
  min-height: 100%;

  .content {
    padding: 20px 30px;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;

    .form_container {
      margin-top: 1.5rem;

      .fields {
        margin-right: -15px;
        margin-left: -15px;

        .name_fields {
          position: relative;
          display: flex;
          flex-wrap: wrap;

          &.block {
            margin-bottom: 1rem;
          }
        }

        .field {
          position: relative;
          flex: 0 0 50%;
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 1rem;

          &.block {
            margin-bottom: 1rem;
          }
        }
      }

      .buttons {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .button {
          margin-right: 1.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.input_container {
  &.dynamic_input {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .option_edit_section {
    .content {
      padding: 0;

      .form_container {
        .fields {
          .field {
            max-width: 100%;
          }
          .name_fields {
            flex-direction: column;
          }
        }
      }
    }
  }

  .search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .option_edit_section {
    margin-left: 0;

    .content {
      padding: 0;

      .form_container {
        .fields {
          flex-direction: column;

          .field {
            max-width: 100%;
          }
        }
      }
    }
  }
}
