@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(59, 59, 59, 0.603);
  z-index: 1000;

  &_content {
    width: 50%;
    border-radius: 8px;
    background-color: $color_white_3;
    color: $color_black_1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  &_message {
    display: block;
    width: 100%;
    padding: 30px 20px;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    box-sizing: border-box;
  }

  &_button {
    @include button-style(100%);
    border-top: 1px solid $color_gray_2;
    font-weight: 500;

    &:hover {
      background: $color_white_1;
      color: $color_accent;
    }
  }
}

@media (max-width: 768px) {
  .modal_content {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .modal_content {
    width: 96%;
  }
}
